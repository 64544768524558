/*******************************/
/*******************************/
/********** Font Faimly ********/
/******************************/
/******************************/
@font-face {
  font-family: "TT Norms Bold";
  src: url("../src/assets/fonts/TTNorms-Bold.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Black";
  src: url("../src/assets/fonts/TT-Norms-Pro-Black.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Extra Bold";
  src: url("../src/assets/fonts/TTNorms-ExtraBold.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Extra Light";
  src: url("../src/assets/fonts/TTNorms-ExtraLight.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Extra Light Italic";
  src: url("../src/assets/fonts/TTNorms-ExtraLightItalic.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Heavy";
  src: url("../src/assets/fonts/TTNorms-Heavy.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Heavy Italic";
  src: url("../src/assets/fonts/TTNorms-HeavyItalic.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Italic";
  src: url("../src/assets/fonts/TTNorms-Italic.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Italic Light";
  src: url("../src/assets/fonts/TTNorms-LightItalic.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Regular";
  src: url("../src/assets/fonts/TTNorms-Regular.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Medium";
  src: url("../src/assets/fonts/ttnorms-medium.woff") format("woff");
  font-variant-ligatures: none;
}

@font-face {
  font-family: "TT Norms Medium Italic";
  src: url("../src/assets/fonts/ttnorms-mediumitalic.woff") format("woff");
  font-variant-ligatures: none;
}

.container {
  max-width: 1440px !important;
  margin: 0px auto;
}

section {
  padding-top: 100px;
}

.section-heading {
  font-family: "TT Norms Heavy Italic";
  font-size: 48px;
  /* font-style: italic; */
  font-weight: 900;
  line-height: 57.6px;
  text-align: center;
  color: #c73646;
  text-transform: uppercase;
  white-space: nowrap;
}

.ticket-section h2 {
  margin-bottom: 90px;
}

.ticket-section img {
  width: 560px;
  height: 348px;
  object-fit: cover;
  margin-bottom: 50px;
}

.form-section .section-heading {
  margin-bottom: 120px;
}

.form-section .step .ticket-form-input {
  margin-bottom: 40px;
}

.form-section .toggle-advertisement {
  width: 211px;
}

.toggle-advertisement svg {
  display: none;
}

.toggle-advertisement .form-switch.form-check-input input {
  background: #dc1e35 !important;
  opacity: 50%;
  height: 19px !important;
  width: 36px !important;
}

.toggle-advertisement .react-switch-handle {
  width: 13px !important;
  height: 13px !important;
  top: 3px !important;
  right: 20px !important;
}

.form-check-input {
  clear: left;
}

.form-check.form-switch.form-switch-md {
  display: flex;
  align-items: self-start;
  justify-content: center;
}

.form-switch.form-switch-md .form-check-input {
  height: 19px;
  width: 36px;
  border-radius: 3rem;
}

.form-switch.form-switch-md .form-check-input:focus {
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #dc1e35;
  border-color: #dc1e35;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23DC1E35%27/%3e%3c/svg%3e);
  border-color: #dc1e35;
}

.form-switch.form-switch-md label {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  color: #dc1e35;
  max-width: 167px;
  margin-left: 7px;
  padding-top: 2px;
}

.responsive-mode {
  display: none;
}

.upload-image-section {
  margin-bottom: 50px;
}

.form-section {
  margin-bottom: 215px;
}

.ticket-section {
  margin-bottom: 140px;
}

.upload-image-section .upload-area .upload-image-description,
.display-section .upload-image-description {
  font-family: "TT Norms Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 17.69px;
  text-align: center;
  color: #c73646;
  text-transform: uppercase;
  margin: 0px auto 15px auto;
}

.upload-image-section .upload-area .upload-image-description.story,
.display-section .upload-image-description.story {
  max-width: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.upload-image-section .upload-area .upload-image-description.post,
.display-section .upload-image-description.post {
  max-width: 222px;
}

.upload-image-section .upload-area .upload-image-description strong {
  font-size: 15px;
  font-weight: 800;
  line-height: 17.69px;
  text-align: center;
  margin-left: 4px;
}

.upload-image-section .upload-area .upload-image-description span {
  font-family: "TT Norms Medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: center;
}

.upload-image-section .upload-button {
  font-family: "TT Norms Regular";
  font-size: 16px;
  font-weight: 800;
  line-height: 17.69px;
  text-align: center;
  padding: 12px 20px;
  color: #ffffff;
  background: #c73646;
  border-radius: 24px;
  white-space: nowrap !important;
}

.upload-image-section .upload-button img {
  width: 18px;
  height: 18px;
}

/* Slider.css */

.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-btn:hover {
  background: none !important;
  color: #c73646 !important;
}

.steps {
  display: flex;
  padding-top: 12px;
}

.steps svg {
  font-size: 12px;
}

.button-save,
.button-undo {
  background-color: #c73646;
  border: none;
  color: white;
  padding: 7px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 7px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 25px;
}

.dot {
  width: 16px;
  height: 16px;
  margin: 0 5px;
  border: 1px solid #c73646;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #c73646;
}

.step-content {
  width: 80%;
  max-width: 800px;
  padding: 20px;
  border-radius: 5px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-platform-modal .modal-content {
  border-radius: 22px !important;
  padding: 8px !important;
  background: #c73646 !important;
  border: none;
  color: #fff !important;
  min-width: 632px !important;
}

.social-platform-modal .modal-header {
  border-bottom: none !important;
  position: relative;
  padding: 15px;
}

.social-platform-modal .cross-icon {
  position: absolute;
  right: 4%;
  top: 90%;
  width: 28px;
}

.social-platform-modal .modal-content p {
  font-family: 'TT Norms Medium';
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 800;
  line-height: 26.54px;
  text-align: center;
  color: #fff;
  margin-bottom: 35px;
}

.social-platform-modal ul {
  margin-bottom: 25px;
}

.social-platform-modal ul li a {
  width: 32px;
  height: 32px;
  margin: 0px 14px 0 14px;
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.social-platform-modal ul li a:hover {
  opacity: 0.8;
}

.social-platform-modal ul li a svg {
  color: #c73646;
  font-size: 26px;
}

.thank-you-section button {
  font-family: 'TT Norms Regular';
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  /* Add font smoothing for better appearance */
  line-height: 17.69px;
  text-align: center;
  background-color: #C73646;
}

.thank-you-section a {
  margin: 0 30px 0 0;
}

.ticket-overlay {
  position: absolute;
  /* top: 50%; */
  height: fit-content !important;
  font-size: 24px;
  font-weight: bold;
  color: rgb(186, 19, 19);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.upload-image-section .story-area,
.upload-image-section .post-area {
  position: relative;
}

.upload-image-section .edit-ticket-icon {
  z-index: 999;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  background: #c73646;
  border-radius: 50px;
  cursor: pointer;
}

.upload-image-section .edit-ticket-icon-image {
  width: 20px;
  height: 20px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Slider.css */

.image-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.display-section-btns {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
}

.display-section-btns button {
  position: relative;
  bottom: 0px;
  white-space: nowrap;
  background-color: #c73646;
  border-radius: 24px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: "TT Norms Medium";
  margin-bottom: 3px;
  width: 150px !important;
  padding: 0px 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px auto 10px auto;
  letter-spacing: 0px;
}

/* .image-placeholder {
  width: 600px; 
  height: 300px; 
  border: 1px solid #ccc;
  margin-right: 10px;
} */

.hidden {
  display: none;
}

button {
  border: none;
  cursor: pointer;
}

/* Example CSS for Step3 component */

.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Define styles for the images */
.image img {
  max-width: 100%;
  /* Ensure image doesn't exceed its container */
  max-height: 100%;
  /* Ensure image doesn't exceed its container */
}

/* Define styles for the placeholder content */
.image-placeholder {
  width: 100%;
  background-color: #ccc;
  /* Set placeholder background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

/* step 4 css */
.display-section .section-heading {
  margin-bottom: 18px;
}

.image-overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ticket-overlay {
  position: absolute;
  left: 10%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.image-section-1 .storyImage-overlay.ticket-overlay {
  bottom: 10%;
  transform: rotate(7.82deg);
  width: 179px;
  height: 111px;
}

.image-section-2 .postImage-overlay.ticket-overlay {
  bottom: 10%;
  transform: rotate(-7.82deg);
  width: 128px;
  height: 79px;
}

/* .image-section-1 .storyImage-overlay.ticket-overlay .ticket-name {
  font-size: 20px;
  text-transform: uppercase;
  line-height: 20.78px;
  display: inline-flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  justify-content: center !important;
  word-break: break-all;
}  */


/* 
 .image-section-2 .postImage-overlay.ticket-overlay .ticket-name {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 14.84px;
  display: inline-flex !important;
  flex-wrap: wrap !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  word-break: break-all;
}  */


.image-section-1 .storyImage-overlay.ticket-overlay .ticket-name {
  font-family: "TT Norms Black";
  /* display: grid; */
  display: flex !important;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center !important;
  justify-content: center !important;
  /* justify-items: center; */
  text-transform: uppercase;
  font-size: 20px;
  line-height: 20.78px;
  line-break: auto;
}

.image-section-2 .postImage-overlay.ticket-overlay .ticket-name {
  font-family: "TT Norms Black";
  font-size: 14px;
  text-transform: uppercase;
  line-height: 14.84px;
  /* display: grid; */
  display: flex !important;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center !important;
  justify-content: center !important;
  /* justify-items: center; */
  overflow: hidden;
  line-break: auto;
}

.ticket-section .ticket-name {
  max-width: 473px;
}


.ticket-name {
  font-family: "TT Norms Black";
  text-shadow: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 65px;
  /* font-size: 60px; */
  font-size: 3.75em;
  font-weight: 900;
  width: 100%;
  height: inherit;
  color: #fff;
  text-transform: uppercase;
  padding: 0 16px;
}

.ticket-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: inherit;
  color: #fff;
  text-transform: uppercase;
  padding: 0 16px;
  font-weight: 900;
  box-shadow: none !important;
  text-shadow: none;
}


.ticket-section .ticket-name {
  top: 56%;
  /* line-break: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden; */
  /* -webkit-line-clamp: 2;
  max-height: calc(4em * 3) !important; */

}

.storyImage-overlay.ticket-overlay .ticket-name,
.postImage-overlay.ticket-overlay .ticket-name {
  top: calc(100% - 37%);
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  letter-spacing: 1px;
  ;

}

.step-content img {
  overflow: hidden;
}

.ticket-image {
  max-width: 100%;
  max-height: 100%;
}

.image-overlay-container {
  overflow: hidden;
  z-index: 999;
}

/* editor cropper */
.image-editor {
  font-family: "Arial", sans-serif;
  text-align: center;
}

.editor-header h1 {
  color: #ff0000;
}

.editor-content {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.edit-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.edit-button,
.save-button,
.undo-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 24px;
}

.upload-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 10px;
}

.image-editor {
  font-family: "Arial", sans-serif;
  text-align: center;
  max-width: 655px;
  margin: auto;
}

.editor-header h1 {
  margin-bottom: 20px;
}

.editor-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preview {
  width: 180px;
  height: 320px;
  background-size: cover;
  background-position: center;
  border: 2px dashed red;
  position: relative;
}

.edit-button,
.upload-button {
  background-color: #ff4757;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.lg-window-size {
  margin-top: 50px;
}

.edit-button:hover,
.upload-button:hover {
  background-color: #ff6b81;
}

/* Step3.css */
.button-disabled {
  background-color: #ccc !important;
  color: #666;
  cursor: not-allowed;
}

.slider-btn.disabled:focus,
.slider-btn.disabled:hover {
  background-color: none !important;
}

.slider-btn.disabled {
  color: #c73646 !important;
  opacity: 0.5;
  cursor: not-allowed;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #c73646;
}

.ticket-container {
  position: relative;
}

.ticket-container img {
  position: relative;
}

.ticket-container .ticket-name {
  z-index: 999;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ticket-overlay img {
  width: 175px;
}

.image-container {
  width: 655px;
}

.image-section-1,
.image-section-2 {
  overflow: hidden;
}

.story-area,
.image-section-1,
.story-area .cropper-modal {
  height: 494px;
  width: 278px;
  border: 1px solid #dc1e35;
  border-radius: 20px;
  background: #ebebeb;
}

.image-section-1.image-preview img,
.image-section-2.image-preview img {
  /* width: 100%;
  height: 100%; */
  width: 278px;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.story-area img {
  height: 494px;
  width: 278px;
  object-fit: cover;
  border-radius: 20px;
}

.post-area,
.image-section-2 {
  height: 278px;
  width: 278px;
  border: 1px solid #dc1e35;
  border-radius: 20px;
  background: #ebebeb;
}

.post-area img {
  height: 278px;
  width: 278px;
  object-fit: cover;
  border-radius: 20px;
}

.post-area .cropper-modal,
.post-area .cropper-wrap-box,
.post-area .cropper-bg {
  height: 278px !important;
  border-radius: 20px !important;
}

.cropper-wrap-box,
.cropper-canvas {
  border-radius: 20px !important;
}

/* thank you page */

.thanks-heading {
  position: absolute;
  top: 30%;
  z-index: 999;
  left: 9%;
}

.thank-you-section h3 {
  text-transform: uppercase;
  z-index: 9;
  font-family: TT Norms;
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  line-height: 57.6px;
  text-align: center;
  color: #c73646;
  max-width: 657px;
  white-space: nowrap;
  font-family: "TT Norms Extra Bold";
  margin-bottom: 350px;
}

/* slider */
.slider-container .slider-btn {
  font-family: "TT Norms Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 17.69px;
  text-align: center;
  color: #c73646;
  background: transparent;
  text-transform: uppercase;
  margin: 0px 14px;
}

.slider-container .slider-btn:focus {
  background: none !important;
}

body {
  touch-action: none !important;
  height: 100vh;
  overflow: hidden;
}

/* ----------- Header ................. */
@import "https://fonts.googleapis.com/css?family=Barlow:400,500,600,700,600i,700i,800i|Roboto:300,400,700&amp;display=fallback";

:root {
  --kodo-font-family: "comic sans";
  --kodo-card-border-width: 0.1rem;
  --kodo-card-border-color: #f1f1f1;
  --kodo-label-font-size: 1.4rem;
  --kodo-label-color: #333;
  --kodo-form-border: 1px solid #f1f1f1;
  --kodo-form-padding: 1rem;
  --kodo-form-background: #f8f8f8;
  --kodo-select-padding: 1rem;
  --kodo-color-font-50: #d8d8d8;
  --kodo-color-border: #e8e8e8;
  --kodo-card-footer-background: #f8f8f8;
  --kodo-card-border-color: #e8e8e8;
  --kodo-card-border-style: solid;
  --kodo-color-placeholder: #777;
  --kodo-color-font: #333;
  --kodo-color-background-75: rgba(37, 37, 37, 0.5);
  --kodo-checkbox-background: #fff;
  --kodo-checkbox-checked-background: #fff;
  --kodo-checkbox-checked-border: 1px solid #c73646;
  --kodo-checkbox-border: 1px solid #c73646;
  --kodo-checkbox-checked-color: #515251;
  --kodo-checkbox-focus-outline: 0.2rem solid #c73646;
  --kodo-radio-mark-height: 1.3rem;
  --kodo-radio-mark-width: 1.3rem;
  --kodo-checkbox-height: 2.1rem;
  --kodo-checkbox-width: 2.1rem;
  --kodo-form-layout-row-gap: clamp(1.5rem,
      calc(1.5rem + 0.5 * (100vw - 48rem) / 80),
      2rem);
  --kodo-form-layout-margin-bottom: clamp(1.5rem,
      calc(1.5rem + 0.5 * (100vw - 48rem) / 80),
      2rem);
  --kodo-size-large: clamp(4rem, calc(4rem + 1 * (100vw - 48rem) / 80), 5rem);
  --kodo-size-normal: clamp(3rem, calc(3rem + 1 * (100vw - 48rem) / 80), 4rem);
  --kodo-size-small: clamp(3rem,
      calc(3rem + 0.4 * (100vw - 48rem) / 80),
      3.4rem);
  font-size: 62.5%;
  --scrollbar-width: 0;
  --color-font: #575757;
  --color-font-dark: #333;
  --color-border: #d3d3d3;
  --color-background: #fff;
  --color-primary: #0093d0;
}

@media (min-aspect-ratio: 3/2) {
  :root {
    font-size: clamp(50%, 28.125% + 0.6vh, 62.5%);
  }
}

body,
html {
  min-height: 100vh;
  width: 100%;
  background: #fff;
}

html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: var(--font-size,
      clamp(1.3rem, calc(1.3rem + 0.3 * (100vw - 48rem) / 80), 1.6rem));
  line-height: 1.5;
  color: #333;
  text-size-adjust: 100%;
  accent-color: #c73646;
}

html[data-theme="purple"] body {
  accent-color: #99258d;
}

html[data-theme="tiber"] body {
  accent-color: #0c3849;
}

html[data-theme="sand"] body {
  accent-color: #f6f6f6;
}

html[data-theme="green"] body {
  accent-color: #320564;
}

html[data-theme="ultra"] body {
  accent-color: #006a49;
}

html[data-theme="summer"] body {
  accent-color: #f47d30;
}

html[data-theme="midnight"] body {
  accent-color: #f6aa00;
}

html[data-theme="ibr"] body {
  accent-color: #1f5ab1;
}

html[data-theme="melavollur"] body {
  accent-color: #817f7a;
}

html[data-theme="skautaholl"] body {
  accent-color: #033a70;
}

body.no-scroll {
  height: 100vh;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

::selection {
  background: #ddd;
}

#__next {
  position: relative;
  min-height: 100vh;
}

.Header_header__NnOSM {
  display: flex;
  position: fixed;
  top: 3rem;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 0.15s;
  overflow: hidden;
}

.Header_header__NnOSM:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.16);
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.Header_header__NnOSM.Header_isSticky__WVhuv:before {
  opacity: 1;
}

@media (max-width: 44.9375em) {
  .Header_header__NnOSM.Header_isMobileSticky___0_xF:before {
    opacity: 1;
  }

  .ticket-section .ticket-name {
    top: 48%;
  }
}

.Header_header__NnOSM.Header_isSticky__WVhuv {
  overflow: visible;
  transform: translateY(-3rem);
}

@media (max-width: 44.9375em) {
  .Header_header__NnOSM.Header_isMobileSticky___0_xF {
    transform: translateY(-3rem);
    box-shadow: 0 0.4rem 5rem rgba(0, 0, 0, 0.16);
  }
}

@media (min-width: 45em) {
  .Header_header__NnOSM.Header_register__Yvufh {
    position: absolute;
  }
}

.Header_header__container__1ZOuJ {
  margin: 0 auto;
  padding-left: 7rem;
  padding-right: 7rem;
  max-width: calc(129rem + 7rem * 2);
}

@supports not (padding: clamp(1rem, 2vw, 3rem)) {
  .Header_header__container__1ZOuJ {
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: calc(129rem + 7rem * 2);
  }

  @media (min-width: 45em) {
    .Header_header__container__1ZOuJ {
      padding-left: 5rem;
      padding-right: 5rem;
      max-width: calc(129rem + 7rem * 2);
    }
  }

  @media (min-width: 67.5em) {
    .Header_header__container__1ZOuJ {
      padding-left: 7rem;
      padding-right: 7rem;
      max-width: calc(129rem + 7rem * 2);
    }
  }
}

@supports (padding: clamp(1rem, 2vw, 3rem)) {
  .Header_header__container__1ZOuJ {
    max-width: calc(129rem + clamp(3rem, 5.4263565891vw, 7rem) * 2);
    padding-left: clamp(3rem, 5.4263565891vw, 7rem);
    padding-right: clamp(3rem, 5.4263565891vw, 7rem);
  }
}

.Header_header__content__w2qML {
  display: flex;
  align-items: stretch;
  position: relative;
}

.Header_register__Yvufh .Header_header__content__w2qML {
  padding-left: 3rem;
}

.Header_header__skew__UqQYk {
  display: none;
  position: absolute;
  top: 0;
  left: -102.5rem;
  right: auto;
  bottom: auto;
  z-index: 900;
  width: 100rem;
  height: 100rem;
  background: #c73646;
  transform: skew(-7deg);
}

@media (min-width: 45em) {
  .Header_header__skew__UqQYk {
    display: block;
    left: -100rem;
  }
}

@media (min-width: 67.5em) {
  .Header_header__skew__UqQYk {
    left: -95rem;
  }
}

html[data-theme="purple"] .Header_header__skew__UqQYk {
  background-color: #99258d;
}

html[data-theme="tiber"] .Header_header__skew__UqQYk {
  background-color: #0c3849;
}

html[data-theme="sand"] .Header_header__skew__UqQYk {
  background-color: #f6f6f6;
}

html[data-theme="green"] .Header_header__skew__UqQYk {
  background-color: #320564;
}

html[data-theme="ultra"] .Header_header__skew__UqQYk {
  background-color: #006a49;
}

html[data-theme="summer"] .Header_header__skew__UqQYk {
  background-color: #f47d30;
}

html[data-theme="midnight"] .Header_header__skew__UqQYk {
  background-color: #f6aa00;
}

html[data-theme="ibr"] .Header_header__skew__UqQYk {
  background-color: #1f5ab1;
}

html[data-theme="melavollur"] .Header_header__skew__UqQYk {
  background-color: #817f7a;
}

html[data-theme="skautaholl"] .Header_header__skew__UqQYk {
  background-color: #033a70;
}

.Header_header__logo__iOMtl {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 228px;
  height: 60px !important;
  font-weight: 700;
  font-size: 3rem;
  color: #fff;
  white-space: nowrap;
  text-shadow: 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.75);
  text-indent: -999.9rem;
  background: 0 no-repeat;
  background-size: contain;
  transition: transform 0.15s;
  /* margin-left: -10px; */
}

.Header_header__logo__iOMtl:focus {
  outline: none;
}

.Header_header__logo__iOMtl.Header_isSvg___JK3m {
  text-indent: 0;
}

@media (max-width: 44.9375em) {
  .Header_register__Yvufh .Header_header__logo__iOMtl {
    display: none;
  }
}

@media (min-width: 30em) {
  .Header_header__logo__iOMtl {
    transform: translate(3rem);
  }

  .Header_header__logo__iOMtl.Header_isSidebar__35QAi {
    transform: translate(13rem);
  }
}

@media (min-width: 45em) {
  .Header_header__logo__iOMtl {
    display: flex;
    transform: translate(7rem);
    height: auto;
  }
}

@media (min-width: 67.5em) {
  .Header_header__logo__iOMtl {
    transform: translate(13rem);
  }
}

.Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  transform: translate(0);
  color: #c73646;
  text-shadow: none;
}

html[data-theme="purple"] .Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  color: #99258d;
}

html[data-theme="tiber"] .Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  color: #0c3849;
}

html[data-theme="sand"] .Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  color: #2e2e2e;
}

html[data-theme="summer"] .Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  color: #f47d30;
}

html[data-theme="midnight"] .Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  color: #176099;
}

html[data-theme="ibr"] .Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  color: #1f5ab1;
}

html[data-theme="melavollur"] .Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  color: #817f7a;
}

html[data-theme="skautaholl"] .Header_isSticky__WVhuv .Header_header__logo__iOMtl {
  color: #033a70;
}

.Header_header__logoSvg__3n1uz {
  display: block;
}

.Header_header__logoSvg__3n1uz.Header_isNorthenLightsRun__Ol9CH {
  margin-left: 1rem;
  width: 18rem;
}

.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isNorthenLightsRun__Ol9CH {
  width: 15rem;
}

@media (min-width: 45em) {
  .Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isNorthenLightsRun__Ol9CH {
    width: 17rem;
  }

  .Header_header__logoSvg__3n1uz.Header_isNorthenLightsRun__Ol9CH {
    width: 21rem;
  }
}

.Header_header__logoSvg__3n1uz.Header_isNorthenLightsRun__Ol9CH path {
  fill: #fff;
}

.Header_isClear__bfzXg .Header_header__logoSvg__3n1uz.Header_isNorthenLightsRun__Ol9CH path,
.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isNorthenLightsRun__Ol9CH path {
  fill: #320564;
}

.Header_header__logoSvg__3n1uz.Header_isUltra__PTa26 {
  width: 30rem;
}

.Header_isRegular__qmthJ .Header_header__logoSvg__3n1uz.Header_isUltra__PTa26 path {
  fill: #fff;
}

.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isUltra__PTa26 {
  width: 20rem;
}

.Header_header__logoSvg__3n1uz.Header_isIBR__TV_Bs {
  width: 30rem;
  color: #fff;
}

.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isIBR__TV_Bs {
  width: 17rem;
}

.Header_isClear__bfzXg .Header_header__logoSvg__3n1uz.Header_isIBR__TV_Bs,
.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isIBR__TV_Bs {
  color: #000;
}

.Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 {
  width: 18rem;
  height: auto;
}

@media (min-width: 45em) {
  .Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 {
    width: auto;
  }
}

.Header_header__NnOSM:not(.Header_isSticky__WVhuv):not(.Header_isClear__bfzXg) .Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 path,
.Header_header__NnOSM:not(.Header_isSticky__WVhuv):not(.Header_isClear__bfzXg) .Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 polyline,
.Header_header__NnOSM:not(.Header_isSticky__WVhuv):not(.Header_isClear__bfzXg) .Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 rect,
.Header_header__logo__iOMtl.Header_isSidebar__35QAi .Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 path,
.Header_header__logo__iOMtl.Header_isSidebar__35QAi .Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 polyline,
.Header_header__logo__iOMtl.Header_isSidebar__35QAi .Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 rect {
  fill: #fff;
}

.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isSummer__3r6z8 {
  width: 18rem;
}

.Header_header__logoSvg__3n1uz.Header_isMelavollur__RBVmc {
  width: 100%;
}

.Header_header__logoSvg__3n1uz.Header_isMelavollur__RBVmc #Text {
  fill: #fff;
}

.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isMelavollur__RBVmc {
  width: 16rem;
}

.Header_isClear__bfzXg .Header_header__logoSvg__3n1uz.Header_isMelavollur__RBVmc #Text,
.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isMelavollur__RBVmc #Text {
  fill: #000;
}

.Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz {
  width: 100%;
  height: auto;
}

.Header_header__NnOSM:not(.Header_isSticky__WVhuv):not(.Header_isClear__bfzXg) .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz path,
.Header_header__NnOSM:not(.Header_isSticky__WVhuv):not(.Header_isClear__bfzXg) .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz polyline,
.Header_header__NnOSM:not(.Header_isSticky__WVhuv):not(.Header_isClear__bfzXg) .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz rect,
.Header_header__logo__iOMtl.Header_isSidebar__35QAi .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz path,
.Header_header__logo__iOMtl.Header_isSidebar__35QAi .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz polyline,
.Header_header__logo__iOMtl.Header_isSidebar__35QAi .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz rect {
  fill: #fff;
}

.Header_header__NnOSM:not(.Header_isSticky__WVhuv):not(.Header_isClear__bfzXg) .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz path:nth-child(2),
.Header_header__logo__iOMtl.Header_isSidebar__35QAi .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz path:nth-child(2) {
  stroke: #fff;
}

.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz {
  width: 18rem;
}

.Header_isClear__bfzXg .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz .cls-1,
.Header_isClear__bfzXg .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz .cls-2,
.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz .cls-1,
.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz .cls-2 {
  fill: #023b7e;
}

.Header_isClear__bfzXg .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz .cls-2,
.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz .cls-2 {
  stroke: #023b7e;
  stroke-miterlimit: 10;
  stroke-width: 0.05rem;
}

.Header_isClear__bfzXg .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz .cls-3,
.Header_isSticky__WVhuv .Header_header__logoSvg__3n1uz.Header_isSkautaholl__F0TXz .cls-3 {
  fill: #e63027;
}

.Header_header__logo__iOMtl.Header_marathon__DoDiA {
  background-image: url("../src/assets/images/logo.svg");
}

.Header_isSticky__WVhuv .Header_header__logo__iOMtl.Header_marathon__DoDiA {
  background-size: auto 5rem;
}

.Header_isClear__bfzXg .Header_header__logo__iOMtl.Header_marathon__DoDiA,
.Header_isSticky__WVhuv .Header_header__logo__iOMtl.Header_marathon__DoDiA {
  /* background-image: url(../../../static/images/marathon-logo.png); */
}

.Header_header__logo__iOMtl.Header_tiber___cyDN {
  /* background-image: url(../../../static/images/logos/rig_logo_white_nospons.png); */
}

.Header_isClear__bfzXg .Header_header__logo__iOMtl.Header_tiber___cyDN,
.Header_isSticky__WVhuv .Header_header__logo__iOMtl.Header_tiber___cyDN {
  /* background-image: url(../../../static/images/logos/rig_logo_nospons.png); */
  background-size: auto 4rem;
}

.Header_header__logo__iOMtl.Header_green__I3vJ1 {
  /* background-image: url(../../../static/images/logos/nlh-english-white.png); */
}

.Header_header__logo__iOMtl.Header_green__I3vJ1:lang(is) {
  /* background-image: url(../../../static/images/logos/nlh-logo-white.png); */
}

.Header_isSticky__WVhuv .Header_header__logo__iOMtl.Header_green__I3vJ1 {
  margin-left: 1rem;
  background-size: auto 3.8rem;
}

.Header_isClear__bfzXg .Header_header__logo__iOMtl.Header_green__I3vJ1,
.Header_isSticky__WVhuv .Header_header__logo__iOMtl.Header_green__I3vJ1 {
  /* background-image: url(../../../static/images/logos/nlh-english.png); */
}

.Header_isClear__bfzXg .Header_header__logo__iOMtl.Header_green__I3vJ1:lang(is),
.Header_isSticky__WVhuv .Header_header__logo__iOMtl.Header_green__I3vJ1:lang(is) {
  /* background-image: url(../../../static/images/logos/nlh-logo.png); */
}

.Header_header__logo__iOMtl.Header_green__I3vJ1.Header_isSvg___JK3m {
  background-image: none;
}

.Header_header__logo__iOMtl.Header_midnight__pEEe7 {
  /* background-image: url(../../../static/images/logos/midnight-logo-white.png); */
  background-size: auto 5.5rem;
}

@media (min-width: 45em) {
  .Header_header__logo__iOMtl.Header_midnight__pEEe7 {
    background-size: auto 7.5rem;
  }
}

.Header_isClear__bfzXg .Header_header__logo__iOMtl.Header_midnight__pEEe7,
.Header_isSticky__WVhuv .Header_header__logo__iOMtl.Header_midnight__pEEe7 {
  /* background-image: url(../../../static/images/logos/midnight-logo.png); */
}

.Header_isSticky__WVhuv .Header_header__logo__iOMtl.Header_midnight__pEEe7 {
  background-size: auto 4.4rem;
}

.Header_header__logo__iOMtl.Header_ibr__ZwTsE.Header_isSvg___JK3m {
  background-image: none;
}

.Header_header__back__17juZ {
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  align-self: center;
  padding: 1rem;
  margin: 0 0 0 -1rem;
  cursor: pointer;
}

.Header_header__back__17juZ:focus {
  outline: none;
}

@media (min-width: 45em) {
  .Header_header__back__17juZ {
    display: none;
  }
}

.Header_header__backIcon__vPyvB {
  display: block;
  color: #c73646;
}

html[data-theme="midnight"] .Header_header__backIcon__vPyvB {
  color: #f6aa00;
}

.Header_header__menu__nn_yR {
  display: flex;
  margin-left: auto;
  position: relative;
  white-space: nowrap;
}

.Header_header__menu__nn_yR:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: #fff;
  transform: skew(-7deg);
}

.Header_header__menu__nn_yR:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1rem;
  width: 5rem;
  transform: skew(-7deg);
  background-color: #c73646;
}

html[data-theme="purple"] .Header_header__menu__nn_yR:after {
  background-color: #99258d;
}

html[data-theme="tiber"] .Header_header__menu__nn_yR:after {
  background-color: #0c3849;
}

html[data-theme="sand"] .Header_header__menu__nn_yR:after {
  background-color: #f6f6f6;
}

html[data-theme="green"] .Header_header__menu__nn_yR:after {
  background-color: #320564;
}

html[data-theme="ultra"] .Header_header__menu__nn_yR:after {
  background-color: #006a49;
}

html[data-theme="summer"] .Header_header__menu__nn_yR:after {
  background-color: #f47d30;
}

html[data-theme="midnight"] .Header_header__menu__nn_yR:after {
  background-color: #f6aa00;
}

html[data-theme="ibr"] .Header_header__menu__nn_yR:after {
  background-color: #1f5ab1;
}

html[data-theme="melavollur"] .Header_header__menu__nn_yR:after {
  background-color: #817f7a;
}

html[data-theme="skautaholl"] .Header_header__menu__nn_yR:after {
  background-color: #033a70;
}

.Header_header__button__wrDaL {
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  text-decoration: none;
  display: none;
  position: relative;
  z-index: 1;
  padding: 0 3rem;
  height: 6rem;
  font-size: clamp(1.6rem, calc(1.6rem + 0.2 * (100vw - 48rem) / 80), 1.8rem);
  font-family: Barlow, sans-serif;
  font-weight: 700;
  font-style: italic;
  line-height: 7rem;
  text-transform: uppercase;
  color: #c73646;
  cursor: pointer;
}

.Header_header__button__wrDaL:focus {
  outline: none;
}

@media (min-width: 45em) {
  .Header_header__button__wrDaL {
    display: block;
    height: 7rem;
  }
}

.Header_header__button__wrDaL:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-left: 0.1rem solid #eee;
  transform: skew(-7deg);
}

.Header_header__button__wrDaL:hover {
  color: rgba(196, 18, 47, 0.5);
}

.Header_header__button__wrDaL.Header_back__LZ23v,
.Header_header__button__wrDaL.Header_isBurger__yVmfy {
  display: block;
  width: 8rem;
  color: #fff;
}

.Header_header__button__wrDaL.Header_back__LZ23v:before,
.Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  border-left-width: 0;
  background: #c73646;
}

html[data-theme="purple"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="purple"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #99258d;
}

html[data-theme="tiber"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="tiber"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #0c3849;
}

html[data-theme="sand"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="sand"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #f6f6f6;
}

html[data-theme="green"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="green"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #320564;
}

html[data-theme="ultra"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="ultra"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #006a49;
}

html[data-theme="summer"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="summer"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #f47d30;
}

html[data-theme="midnight"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="midnight"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #f6aa00;
}

html[data-theme="ibr"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="ibr"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #1f5ab1;
}

html[data-theme="skautaholl"] .Header_header__button__wrDaL.Header_back__LZ23v:before,
html[data-theme="skautaholl"] .Header_header__button__wrDaL.Header_isBurger__yVmfy:before {
  background-color: #033a70;
}

.Header_header__button__wrDaL.Header_back__LZ23v {
  display: flex;
  align-items: center;
  width: auto;
}

.Header_header__button__wrDaL.Header_back__LZ23v:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

html[data-theme="midnight"] .Header_header__button__wrDaL.Header_back__LZ23v {
  color: #fff;
}

html[data-theme="midnight"] .Header_header__button__wrDaL.Header_back__LZ23v:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.Header_header__button__wrDaL.Header_back__LZ23v:after {
  content: "";
  position: relative;
  margin-left: 1rem;
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.5rem solid #fff;
}

.Header_active__Ou1eJ .Header_header__button__wrDaL.Header_back__LZ23v:after {
  transform: rotate(180deg);
}

.Header_header__button__wrDaL.Header_isSearch__A7xrY {
  display: none;
  align-items: center;
  padding: 0 2.5rem;
}

@media (min-width: 45em) {
  .Header_header__button__wrDaL.Header_isSearch__A7xrY {
    display: flex;
  }
}

html[data-theme="purple"] .Header_header__button__wrDaL {
  color: #99258d;
}

html[data-theme="purple"] .Header_header__button__wrDaL:hover {
  color: rgba(153, 37, 141, 0.5);
}

html[data-theme="tiber"] .Header_header__button__wrDaL {
  color: #0c3849;
}

html[data-theme="tiber"] .Header_header__button__wrDaL:hover {
  color: rgba(12, 56, 73, 0.5);
}

html[data-theme="sand"] .Header_header__button__wrDaL {
  color: #2e2e2e;
}

html[data-theme="sand"] .Header_header__button__wrDaL:hover {
  color: rgba(46, 46, 46, 0.5);
}

html[data-theme="green"] .Header_header__button__wrDaL {
  color: #320564;
}

html[data-theme="green"] .Header_header__button__wrDaL:hover {
  color: rgba(50, 5, 100, 0.5);
}

html[data-theme="ultra"] .Header_header__button__wrDaL {
  color: #006a49;
}

html[data-theme="ultra"] .Header_header__button__wrDaL:hover {
  color: rgba(0, 106, 73, 0.5);
}

html[data-theme="summer"] .Header_header__button__wrDaL {
  color: #f47d30;
}

html[data-theme="summer"] .Header_header__button__wrDaL:hover {
  color: rgba(244, 125, 48, 0.5);
}

html[data-theme="midnight"] .Header_header__button__wrDaL {
  color: #176099;
}

html[data-theme="midnight"] .Header_header__button__wrDaL:hover {
  color: rgba(23, 96, 153, 0.5);
}

html[data-theme="ibr"] .Header_header__button__wrDaL {
  color: #2e2e2e;
}

html[data-theme="ibr"] .Header_header__button__wrDaL:hover {
  color: #1f5ab1;
}

html[data-theme="melavollur"] .Header_header__button__wrDaL {
  color: #2e2e2e;
}

html[data-theme="melavollur"] .Header_header__button__wrDaL:hover {
  color: #817f7a;
}

html[data-theme="skautaholl"] .Header_header__button__wrDaL {
  color: #2e2e2e;
}

html[data-theme="skautaholl"] .Header_header__button__wrDaL:hover {
  color: #033a70;
}

.Header_header__buttonLabel__M1wfO {
  position: relative;
}

.Header_header__buttonLine__uy_nD {
  position: absolute;
  top: 50%;
  left: 3rem;
  width: 32%;
  height: 0.2rem;
  pointer-events: none;
  background: #fff;
}

html[data-theme="sand"] .Header_header__buttonLine__uy_nD {
  background: #2e2e2e;
}

.Header_header__buttonLine__uy_nD:first-child {
  transform: translate(-0.1rem, 0.7rem);
}

.Header_header__buttonLine__uy_nD:nth-child(2) {
  width: 25%;
  transform: translate(0);
}

.Header_header__buttonLine__uy_nD:nth-child(3) {
  transform: translate(0.1rem, -0.7rem);
}

.Header_header__button__wrDaL.Header_isBurger__yVmfy:hover .Header_header__buttonLine__uy_nD {
  opacity: 0.75;
}

.Header_header__arrow__syJOB {
  transform: scaleX(-1);
  margin-right: 2rem;
}

.Header_header__search__dQ_XP path {
  fill: #c73646;
}

html[data-theme="purple"] .Header_header__search__dQ_XP path {
  fill: #99258d;
}

html[data-theme="green"] .Header_header__search__dQ_XP path {
  fill: #320564;
}

html[data-theme="tiber"] .Header_header__search__dQ_XP path {
  fill: #0c3849;
}

html[data-theme="sand"] .Header_header__search__dQ_XP path {
  fill: #2e2e2e;
}

html[data-theme="ultra"] .Header_header__search__dQ_XP path {
  fill: #006a49;
}

html[data-theme="summer"] .Header_header__search__dQ_XP path {
  fill: #f47d30;
}

html[data-theme="midnight"] .Header_header__search__dQ_XP path {
  fill: #176099;
}

html[data-theme="ibr"] .Header_header__search__dQ_XP path,
html[data-theme="melavollur"] .Header_header__search__dQ_XP path {
  fill: #2e2e2e;
}

html[data-theme="skautaholl"] .Header_header__search__dQ_XP path {
  fill: #033a70;
}

.Header_header__button__wrDaL:hover .Header_header__search__dQ_XP {
  opacity: 0.5;
}

html[data-theme="ibr"] .Header_header__button__wrDaL:hover .Header_header__search__dQ_XP {
  opacity: 1;
}

html[data-theme="ibr"] .Header_header__button__wrDaL:hover .Header_header__search__dQ_XP path {
  fill: #1f5ab1;
}

html[data-theme="melavollur"] .Header_header__button__wrDaL:hover .Header_header__search__dQ_XP {
  opacity: 1;
}

html[data-theme="melavollur"] .Header_header__button__wrDaL:hover .Header_header__search__dQ_XP path {
  fill: #817f7a;
}

html[data-theme="skautaholl"] .Header_header__button__wrDaL:hover .Header_header__search__dQ_XP {
  opacity: 1;
}

html[data-theme="skautaholl"] .Header_header__button__wrDaL:hover .Header_header__search__dQ_XP path {
  fill: #033a70;
}

.Header_header__languages__pvEL5 {
  position: relative;
}

.Header_header__languagesList__Y0DrM {
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  list-style: none;
  position: fixed;
  top: 9rem;
  right: -0.5rem;
  z-index: 1;
  padding: 1rem 1.5rem;
  min-width: 15rem;
  transition: top 0.15s;
}

.Header_header__languagesList__Y0DrM.Header_isMobileSticky___0_xF {
  top: 6rem;
}

@media (min-width: 45em) {
  .Header_header__languagesList__Y0DrM {
    position: absolute;
    top: 10rem;
    transition: none;
  }
}

.Header_header__languagesList__Y0DrM:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: #c73646;
  transform: skew(-7deg);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.12);
}

html[data-theme="midnight"] .Header_header__languagesList__Y0DrM:after {
  background-color: #f6aa00;
}

.Header_header__languagesItem__iHo4E {
  color: inherit;
  text-decoration: none;
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  font-size: clamp(1.3rem, calc(1.3rem + 0.2 * (100vw - 48rem) / 80), 1.5rem);
  font-family: Barlow, sans-serif;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  line-height: 1.15;
  position: relative;
  z-index: 1;
  display: block;
  padding: 0.5rem 0;
  text-align: left;
  color: #fff;
  transition: transform 0.2s ease-in-out;
}

.Header_header__languagesItem__iHo4E:focus {
  outline: none;
}

.Header_header__languagesItem__iHo4E.Header_pos-5__MHumN {
  padding-left: 2rem;
}

.Header_header__languagesItem__iHo4E.Header_pos-4__CA3y3 {
  padding-left: 1.5rem;
}

.Header_header__languagesItem__iHo4E.Header_pos-3__2_WuP {
  padding-left: 1rem;
}

.Header_header__languagesItem__iHo4E.Header_pos-2__2JP47 {
  padding-left: 0.5rem;
}

.Header_header__languagesItem__iHo4E:hover {
  transform: translate(0.5rem);
}

.Header_header__user__PySV9 {
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 2;
  padding: 0 3rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.Header_header__user__PySV9:focus {
  outline: none;
}

.Header_header__user__PySV9:hover {
  opacity: 0.5;
}

.Header_header__user__PySV9:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-left: 0.1rem solid #eee;
  transform: skew(-7deg);
}

.Header_header__userIcon__v1PR1 {
  position: relative;
  top: -0.2rem;
}

.Header_header__email__TmtQK {
  display: none;
  padding-right: 0.2rem;
  max-width: 24rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: clamp(1.2rem, calc(1.2rem + 0.3 * (100vw - 48rem) / 80), 1.5rem);
  font-weight: 600;
  font-style: italic;
  line-height: 7rem;
  text-transform: uppercase;
  color: #c73646;
}

html[data-theme="midnight"] .Header_header__email__TmtQK {
  color: #f6aa00;
}

@media (min-width: 60em) {
  .Header_header__email__TmtQK {
    display: block;
  }
}

.Sidebar_sidebar__aRmKZ {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  visibility: hidden;
  width: 100%;
  will-change: transform, opacity;
}

@media (min-width: 30em) {
  .Sidebar_sidebar__aRmKZ {
    width: 50rem;
  }
}

.Sidebar_sidebar__content__TN7Ol {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 100%;
  color: #fff;
}

html[data-theme="sand"] .Sidebar_sidebar__content__TN7Ol {
  color: #2e2e2e;
}

.Sidebar_sidebar__background__djtNb {
  position: absolute;
  top: 0;
  left: 0;
  right: -100%;
  bottom: 0;
  padding-top: calc(100vh - 7rem);
  background: #c73646;
}

html[data-theme="purple"] .Sidebar_sidebar__background__djtNb {
  background: #99258d;
}

html[data-theme="tiber"] .Sidebar_sidebar__background__djtNb {
  background: #0c3849;
}

html[data-theme="sand"] .Sidebar_sidebar__background__djtNb {
  background: #f6f6f6;
}

html[data-theme="green"] .Sidebar_sidebar__background__djtNb {
  background: #320564;
}

html[data-theme="ultra"] .Sidebar_sidebar__background__djtNb {
  background: #006a49;
}

html[data-theme="summer"] .Sidebar_sidebar__background__djtNb {
  background: #f47d30;
}

html[data-theme="midnight"] .Sidebar_sidebar__background__djtNb {
  background: #f6aa00;
}

html[data-theme="ibr"] .Sidebar_sidebar__background__djtNb {
  background: #1f5ab1;
}

html[data-theme="melavollur"] .Sidebar_sidebar__background__djtNb {
  background: #817f7a;
}

html[data-theme="skautaholl"] .Sidebar_sidebar__background__djtNb {
  background: #033a70;
}

@media (min-width: 30em) {
  .Sidebar_sidebar__background__djtNb {
    transform: skewX(-5deg);
  }
}

.Sidebar_sidebar__background__djtNb:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  background: #a30f27;
}

html[data-theme="purple"] .Sidebar_sidebar__background__djtNb:after {
  background: #8d2282;
}

html[data-theme="tiber"] .Sidebar_sidebar__background__djtNb:after {
  background: #0a2e3c;
}

html[data-theme="sand"] .Sidebar_sidebar__background__djtNb:after {
  background: #eee;
}

html[data-theme="green"] .Sidebar_sidebar__background__djtNb:after {
  background: #2b0455;
}

html[data-theme="ultra"] .Sidebar_sidebar__background__djtNb:after {
  background: #005b3e;
}

html[data-theme="summer"] .Sidebar_sidebar__background__djtNb:after {
  background: #f37421;
}

html[data-theme="midnight"] .Sidebar_sidebar__background__djtNb:after {
  background: #e79f00;
}

html[data-theme="ibr"] .Sidebar_sidebar__background__djtNb:after {
  background: #1d53a4;
}

html[data-theme="skautaholl"] .Sidebar_sidebar__background__djtNb:after {
  background: #033261;
}

html[data-theme="melavollur"] .Sidebar_sidebar__background__djtNb:after {
  background: #797773;
}

.Sidebar_sidebar__header__UrqEj {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 0 3rem 3rem;
}

.Sidebar_sidebar__logo__bKY_O {
  margin-right: auto;
  margin-left: 0;
}

@media (min-width: 45em) {
  .Sidebar_sidebar__logo__bKY_O {
    display: none;
  }
}

.Sidebar_sidebar__button__k4Fqn {
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 8rem;
  height: 7rem;
  text-align: center;
  line-height: 7rem;
  cursor: pointer;
}

.Sidebar_sidebar__button__k4Fqn:focus {
  outline: none;
}

.Sidebar_sidebar__button__k4Fqn:before {
  content: "";
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.1);
  transform: skew(-7deg);
}

.Sidebar_sidebar__button__k4Fqn:last-child:before {
  display: block;
}

.Sidebar_sidebar__button__k4Fqn svg {
  display: block;
  margin: 0;
  padding: 0;
}

.Sidebar_sidebar__button__k4Fqn svg path {
  fill: #fff;
}

html[data-theme="sand"] .Sidebar_sidebar__button__k4Fqn svg path {
  fill: #2e2e2e;
}

.Sidebar_sidebar__inner__Aj7cc {
  padding: 0 0 3rem;
  overflow: hidden;
  overflow-y: auto;
}

.Sidebar_sidebar__list__eZP4L {
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  list-style: none;
  padding: 0 0 0 5rem;
  overflow: hidden;
}

@media (min-width: 45em) {
  .Sidebar_sidebar__list__eZP4L {
    padding: 0 0 0 8rem;
  }
}

.Sidebar_sidebar__item__A37pb {
  position: relative;
}

@media (max-width: 29.9375em) {
  .Sidebar_sidebar__item__A37pb {
    left: 0 !important;
  }
}

.Sidebar_sidebar__footer__0j7fX {
  padding: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  list-style: none;
  display: flex;
  flex: 0 0 7rem;
  margin: auto 0 0;
  height: 7rem;
}

@media (min-width: 30em) {
  .Sidebar_sidebar__footer__0j7fX {
    margin-left: -10%;
  }
}

.Sidebar_sidebar__footerItem__fcL40 {
  flex: 1 1 50%;
  text-align: center;
  line-height: 7rem;
  border-left: 0.1rem solid #c73646;
  background: rgba(0, 0, 0, 0.025);
}

html[data-theme="purple"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #99258d;
}

html[data-theme="tiber"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #0c3849;
}

html[data-theme="sand"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #f6f6f6;
}

html[data-theme="green"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #320564;
}

html[data-theme="ultra"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #006a49;
}

html[data-theme="summer"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #f47d30;
}

html[data-theme="midnight"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #f6aa00;
}

html[data-theme="ibr"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #1f5ab1;
}

html[data-theme="melavollur"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #817f7a;
}

html[data-theme="skautaholl"] .Sidebar_sidebar__footerItem__fcL40 {
  border-color: #033a70;
}

@media (min-width: 30em) {
  .Sidebar_sidebar__footerItem__fcL40 {
    background: transparent !important;
  }
}

.Sidebar_sidebar__footerItem__fcL40:first-child {
  border-left: none;
}

.Sidebar_sidebar__footerLink__V8qUC {
  color: inherit;
  text-decoration: none;
  font-family: Barlow, sans-serif;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-style: italic;
}

.Sidebar_sidebar__footerLink__V8qUC:focus {
  outline: none;
}

.Sidebar_sidebar__footerLink__V8qUC.Sidebar_en__CVuAO:before,
.Sidebar_sidebar__footerLink__V8qUC.Sidebar_is__SEBDW:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 0.4rem;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  /* background: url(../../../static/images/flags/gb.svg) no-repeat #000; */
  background-size: cover;
  border-radius: 50%;
}

.Sidebar_sidebar__footerLink__V8qUC.Sidebar_en__CVuAO.Sidebar_is__SEBDW:before,
.Sidebar_sidebar__footerLink__V8qUC.Sidebar_is__SEBDW.Sidebar_is__SEBDW:before {
  /* background-image: url(../../../static/images/flags/is.svg); */
}

.Sidebar_sidebar__overlay__8WCLi {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1099;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  will-change: opacity;
}

.SidebarItem_sidebarItem__WbF3B {
  font-family: Barlow, sans-serif;
}

.SidebarItem_sidebarItem__title__zT0Oi {
  padding: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  margin: 0.6rem 0 1rem;
  font-size: clamp(2.6rem, calc(2.6rem + 0.4 * (100vw - 48rem) / 80), 3rem);
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
  text-align: left;
  color: #fff;
  line-height: 1.1;
  cursor: pointer;
  transition: transform 0.3s;
  will-change: transform;
}

.SidebarItem_sidebarItem__title__zT0Oi:focus {
  outline: none;
}

html[data-theme="sand"] .SidebarItem_sidebarItem__title__zT0Oi {
  color: #2e2e2e;
}

.SidebarItem_sidebarItem__title__zT0Oi:hover {
  transform: translateX(0.5rem);
}

.SidebarItem_sidebarItem__list__bpacQ {
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  list-style: none;
  will-change: opacity;
}

.SidebarItem_sidebarItem__item__b1SBj {
  position: relative;
  will-change: transform, opacity;
}

@media (max-width: 29.9375em) {
  .SidebarItem_sidebarItem__item__b1SBj {
    left: 0 !important;
  }
}

.SidebarItem_sidebarItem__item__b1SBj:first-child {
  padding-top: 1rem;
}

.SidebarItem_sidebarItem__item__b1SBj:last-child {
  padding-bottom: 3rem;
}

.SidebarItem_sidebarItem__link__kku_I {
  color: inherit;
  text-decoration: none;
  display: block;
  margin-bottom: 0.2rem;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  transition: transform 0.3s;
  will-change: transform;
}

.SidebarItem_sidebarItem__link__kku_I:focus {
  outline: none;
}

html[data-theme="sand"] .SidebarItem_sidebarItem__link__kku_I {
  color: #2e2e2e;
}

.SidebarItem_sidebarItem__link__kku_I:hover {
  transform: translateX(0.5rem);
}

.HeaderCart_cart__Mev9D {
  z-index: 1010;
  padding: 2rem;
  font-size: 1em;
  background-color: #fff;
  min-width: 25rem;
  box-shadow: 0 0.5rem 3rem -0.5rem rgba(0, 0, 0, 0.15);
}

.HeaderCart_cart__arrow__0hS05 {
  fill: #fff;
  width: 2rem;
  height: 1rem;
}

.HeaderCart_cart__seperator__L6y7E {
  margin: 1.5rem 0;
  border-bottom: 1px solid #f1f1f1;
}

.HeaderCart_cart__list__FP4I5 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.HeaderCart_cart__checkout__twAkU {
  text-align: right;
}

.HeaderCart_cart__link__d6dq0 {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 1rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
  background: #c73646;
}

.HeaderCart_cart__link__d6dq0:focus {
  outline: none;
}

.HeaderCart_cart__link__d6dq0:focus-visible {
  outline: 2px solid #c73646;
}

.HeaderCart_cart__link__d6dq0:hover {
  outline: 0;
  background-color: #333;
}

.HeaderCart_cart__link__d6dq0+.HeaderCart_cart__link__d6dq0 {
  margin-top: 0.8rem;
}

.HeaderCart_cart__total__jeFxY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.HeaderCart_item__WAOMK {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.5rem;
}

.HeaderCart_item__details__uyiqs {
  display: flex;
  flex-direction: column;
}

.HeaderCart_item__name__TJRAe {
  font-size: 0.9em;
  opacity: 0.7;
}

.HeaderCart_item__remove__tCdjy {
  padding: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.2rem 0 0;
  width: 1.9rem;
  height: 1.9rem;
  font-size: 1.6rem;
  line-height: 1.9rem;
  border: 1px solid #333;
  border-radius: 50%;
  opacity: 0.2;
  cursor: pointer;
}

.HeaderCart_item__remove__tCdjy:focus {
  outline: none;
}

.HeaderCart_item__remove__tCdjy:before {
  content: "×";
}

.HeaderCart_item__remove__tCdjy:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4.4rem;
  height: 4.4rem;
}

.HeaderCart_item__remove__tCdjy:focus-visible,
.HeaderCart_item__remove__tCdjy:hover {
  opacity: 0.8;
  color: #c73646;
  border: 1px solid #c73646;
}

.HeaderCart_trigger__pUA2r {
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  text-decoration: none;
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 0 clamp(2rem, calc(2rem + 0.5 * (100vw - 48rem) / 80), 2.5rem);
  height: 6rem;
  font-size: clamp(1.6rem, calc(1.6rem + 0.2 * (100vw - 48rem) / 80), 1.8rem);
  font-family: Barlow, sans-serif;
  font-weight: 700;
  font-style: italic;
  line-height: 7rem;
  text-transform: uppercase;
  color: #c73646;
  cursor: pointer;
}

.HeaderCart_trigger__pUA2r:focus {
  outline: none;
}

.HeaderCart_trigger__pUA2r svg {
  grid-column: 1/2;
  grid-row: 1/2;
  display: block;
  width: 2rem;
  height: 2rem;
}

@media (min-width: 45em) {
  .HeaderCart_trigger__pUA2r {
    height: 7rem;
    margin-left: 0;
  }

  .HeaderCart_trigger__pUA2r:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-left: 0.1rem solid #eee;
    transform: skew(-7deg);
  }
}

.HeaderCart_trigger__pUA2r:hover {
  color: rgba(196, 18, 47, 0.5);
}

.HeaderCart_trigger__counter__on1I4 {
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.6rem;
  width: 1.6rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  background-color: #c73646;
  border-radius: 50%;
  transform: translate(1rem, 0.8rem);
}

html[data-theme="purple"] .HeaderCart_trigger__pUA2r {
  color: #99258d;
}

html[data-theme="purple"] .HeaderCart_trigger__pUA2r:hover {
  color: rgba(153, 37, 141, 0.5);
}

html[data-theme="tiber"] .HeaderCart_trigger__pUA2r {
  color: #0c3849;
}

html[data-theme="tiber"] .HeaderCart_trigger__pUA2r:hover {
  color: rgba(12, 56, 73, 0.5);
}

html[data-theme="sand"] .HeaderCart_trigger__pUA2r {
  color: #2e2e2e;
}

html[data-theme="sand"] .HeaderCart_trigger__pUA2r:hover {
  color: rgba(46, 46, 46, 0.5);
}

html[data-theme="green"] .HeaderCart_trigger__pUA2r {
  color: #320564;
}

html[data-theme="green"] .HeaderCart_trigger__pUA2r:hover {
  color: rgba(50, 5, 100, 0.5);
}

html[data-theme="ultra"] .HeaderCart_trigger__pUA2r {
  color: #006a49;
}

html[data-theme="ultra"] .HeaderCart_trigger__pUA2r:hover {
  color: rgba(0, 106, 73, 0.5);
}

html[data-theme="summer"] .HeaderCart_trigger__pUA2r {
  color: #f47d30;
}

html[data-theme="summer"] .HeaderCart_trigger__pUA2r:hover {
  color: rgba(244, 125, 48, 0.5);
}

html[data-theme="midnight"] .HeaderCart_trigger__pUA2r {
  color: #176099;
}

html[data-theme="midnight"] .HeaderCart_trigger__pUA2r:hover {
  color: rgba(23, 96, 153, 0.5);
}

html[data-theme="ibr"] .HeaderCart_trigger__pUA2r {
  color: #2e2e2e;
}

html[data-theme="ibr"] .HeaderCart_trigger__pUA2r:hover {
  color: #1f5ab1;
}

html[data-theme="melavollur"] .HeaderCart_trigger__pUA2r {
  color: #2e2e2e;
}

html[data-theme="melavollur"] .HeaderCart_trigger__pUA2r:hover {
  color: #817f7a;
}

html[data-theme="skautaholl"] .HeaderCart_trigger__pUA2r {
  color: #2e2e2e;
}

html[data-theme="skautaholl"] .HeaderCart_trigger__pUA2r:hover {
  color: #033a70;
}

.Headline_headline__UC8XB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  white-space: nowrap;
  /* margin: 1rem 0 1.6rem 1.6rem; */
  margin: 0;
  opacity: 1;
  line-height: 1;
  color: #555;
  transition: opacity 0.2s ease-in-out;
}

.Headline_headline__UC8XB.Headline_hasTop__g07MQ {
  margin-top: 0;
}

.Headline_headline__UC8XB.Headline_hasBottom__1O66n {
  margin-bottom: 0;
}

.Headline_headline__UC8XB.Headline_isSplit__VW_3J {
  opacity: 1;
}

.Headline_headline__UC8XB:before {
  content: "";
  position: absolute;
  display: none;
  top: -1rem;
  bottom: -1.6rem;
  z-index: 1;
  width: 2.5rem;
  background: #c73646;
  transform: skew(-7deg);
  background-color: #c73646;
}

html[data-theme="purple"] .Headline_headline__UC8XB:before {
  background-color: #99258d;
}

html[data-theme="tiber"] .Headline_headline__UC8XB:before {
  background-color: #0c3849;
}

html[data-theme="sand"] .Headline_headline__UC8XB:before {
  background-color: #f6f6f6;
}

html[data-theme="green"] .Headline_headline__UC8XB:before {
  background-color: #320564;
}

html[data-theme="ultra"] .Headline_headline__UC8XB:before {
  background-color: #006a49;
}

html[data-theme="summer"] .Headline_headline__UC8XB:before {
  background-color: #f47d30;
}

html[data-theme="midnight"] .Headline_headline__UC8XB:before {
  background-color: #f6aa00;
}

html[data-theme="ibr"] .Headline_headline__UC8XB:before {
  background-color: #1f5ab1;
}

html[data-theme="melavollur"] .Headline_headline__UC8XB:before {
  background-color: #817f7a;
}

html[data-theme="skautaholl"] .Headline_headline__UC8XB:before {
  background-color: #033a70;
}

.Headline_headline__UC8XB.Headline_isSplit__VW_3J:before {
  display: block;
}

.Headline_headline__UC8XB.Headline_offset-1__PcGN_:before {
  left: -1.7rem;
}

.Headline_headline__UC8XB.Headline_offset-2__izQwN:before {
  left: -2.5rem;
}

.Headline_headline__UC8XB.Headline_offset-3__EDEho:before {
  left: -3rem;
}

.Headline_headline__UC8XB.Headline_offset-4__8VeYO:before {
  left: -3.5rem;
}

.Headline_headline__UC8XB.Headline_offset-5__0SNF5:before {
  left: -4rem;
}

.Headline_headline__UC8XB.Headline_offset-6__me2uf:before {
  left: -4.5rem;
}

.Headline_headline__UC8XB.Headline_offset-7__NUWUQ:before {
  left: -5rem;
}

.Headline_headline__UC8XB.Headline_offset-8__RNfr2:before {
  left: -5.5rem;
}

.Headline_headline__UC8XB.Headline_hasTop__g07MQ:before {
  top: 0;
}

.Headline_headline__UC8XB.Headline_hasBottom__1O66n:before {
  bottom: 0;
}

.Headline_headline__UC8XB.Headline_hasBottomLink__Xxu7O:before {
  display: none;
}

.Headline_headline__wrapper__bpYIi {
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  font-size: clamp(3rem, calc(3rem + 4 * (100vw - 48rem) / 80), 7rem);
  font-family: Barlow, sans-serif;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  line-height: 1.15;
  position: relative;
  z-index: 1;
  padding: 0px 20px;
  color: #fff;
  /* letter-spacing: -2px; */
  /* min-width: 757px; */
}

.Headline_hasBottomLink__Xxu7O .Headline_headline__wrapper__bpYIi {
  padding: 0 15px;
}

/* .Headline_hasBottomLink__Xxu7O .Headline_headline__wrapper__bpYIi {
  padding: 0 3.6rem;
} */
.Headline_isSplit__VW_3J .Headline_headline__wrapper__bpYIi {
  padding: 0;
}

.Headline_headline__line__8RHow {
  display: inline-block;
  position: relative;
  text-align: left;
  padding: 0 3.6rem 0 1.6rem;
}

.Headline_hasBottomLink__Xxu7O .Headline_headline__line__8RHow {
  padding: 0 3.6rem;
}

.Headline_headline__wrapper__bpYIi:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  bottom: -1.6rem;
  z-index: -1;
  transform: skew(-8deg);
  background-color: #c73646;
}
.Headline_headline__wrapper__bpYIi.two:before{
  top: 0rem;
}

html[data-theme="purple"] .Headline_headline__wrapper__bpYIi:before {
  background-color: #99258d;
}

html[data-theme="tiber"] .Headline_headline__line__8RHow:before {
  background-color: #0c3849;
}

html[data-theme="sand"] .Headline_headline__line__8RHow:before {
  background-color: #f6f6f6;
}

html[data-theme="green"] .Headline_headline__line__8RHow:before {
  background-color: #320564;
}

html[data-theme="ultra"] .Headline_headline__line__8RHow:before {
  background-color: #006a49;
}

html[data-theme="summer"] .Headline_headline__line__8RHow:before {
  background-color: #f47d30;
}

html[data-theme="midnight"] .Headline_headline__line__8RHow:before {
  background-color: #f6aa00;
}

html[data-theme="ibr"] .Headline_headline__line__8RHow:before {
  background-color: #1f5ab1;
}

html[data-theme="melavollur"] .Headline_headline__line__8RHow:before {
  background-color: #817f7a;
}

html[data-theme="skautaholl"] .Headline_headline__line__8RHow:before {
  background-color: #033a70;
}

.Headline_headline__line__8RHow:nth-child(2) {
  left: -1rem;
}

.Headline_headline__line__8RHow:nth-child(3) {
  left: -2rem;
}

.Headline_headline__line__8RHow:nth-child(4) {
  left: -3rem;
}

.Headline_headline__line__8RHow:nth-child(5) {
  left: -4rem;
}

.Headline_headline__line__8RHow:nth-child(6) {
  left: -5rem;
}

.Headline_headline__top__sb7H4 {
  color: inherit;
  text-decoration: none;
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  font-size: clamp(1.2rem, calc(1.2rem + 0.6 * (100vw - 48rem) / 80), 1.8rem);
  font-family: Barlow, sans-serif;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  line-height: 1.15;
  display: inline-block;
  position: relative;
  z-index: 10;
  left: 0.75rem;
  padding: 1rem 3.6rem 0.9rem 1.6rem;
  color: #fff;
}

.Headline_headline__top__sb7H4:focus {
  outline: none;
}

.Headline_hasBottomLink__Xxu7O .Headline_headline__top__sb7H4 {
  padding: 1rem 3.6rem 0.9rem;
}

html[data-theme="sand"] .Headline_headline__top__sb7H4 {
  color: #2e2e2e;
}

.Headline_headline__top__sb7H4:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  z-index: -1;
  transform: skew(-7deg);
  background-color: #c73646;
}

html[data-theme="purple"] .Headline_headline__top__sb7H4:before {
  background-color: #99258d;
}

html[data-theme="tiber"] .Headline_headline__top__sb7H4:before {
  background-color: #0c3849;
}

html[data-theme="sand"] .Headline_headline__top__sb7H4:before {
  background-color: #f6f6f6;
}

html[data-theme="green"] .Headline_headline__top__sb7H4:before {
  background-color: #320564;
}

html[data-theme="ultra"] .Headline_headline__top__sb7H4:before {
  background-color: #006a49;
}

html[data-theme="summer"] .Headline_headline__top__sb7H4:before {
  background-color: #f47d30;
}

html[data-theme="midnight"] .Headline_headline__top__sb7H4:before {
  background-color: #f6aa00;
}

html[data-theme="ibr"] .Headline_headline__top__sb7H4:before {
  background-color: #1f5ab1;
}

html[data-theme="melavollur"] .Headline_headline__top__sb7H4:before {
  background-color: #817f7a;
}

html[data-theme="skautaholl"] .Headline_headline__top__sb7H4:before {
  background-color: #033a70;
}

.Headline_headline__topIcon__N0MKT {
  transform: scaleX(-1);
  margin-right: 1.5rem;
  transition: transform 0.2s ease-in-out;
}

.Headline_headline__top__sb7H4.Headline_isLink__Q8SyN:hover .Headline_headline__topIcon__N0MKT {
  transform: scaleX(-1) translate(0.2rem);
}

.Headline_headline__bottom__yA_No {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  font-size: clamp(1.6rem, calc(1.6rem + 0.4 * (100vw - 48rem) / 80), 2rem);
  font-family: Barlow, sans-serif;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  line-height: 1.15;
  display: inline-block;
  position: relative;
  z-index: 3;
  margin: 0.6rem 0 0;
  padding: 1rem 3.6rem 1rem 1.8rem;
}

.Headline_headline__bottom__yA_No:focus {
  outline: none;
}

.Headline_hasBottomLink__Xxu7O .Headline_headline__bottom__yA_No {
  margin-top: 1.6rem;
  padding: 1rem 3.6rem;
}

.Headline_offset-1__PcGN_ .Headline_headline__bottom__yA_No {
  left: -0.9rem;
}

.Headline_offset-2__izQwN .Headline_headline__bottom__yA_No {
  left: -1.9rem;
}

.Headline_offset-3__EDEho .Headline_headline__bottom__yA_No {
  left: -2.9rem;
}

.Headline_offset-4__8VeYO .Headline_headline__bottom__yA_No {
  left: -3.9rem;
}

.Headline_offset-5__0SNF5 .Headline_headline__bottom__yA_No {
  left: -4.9rem;
}

.Headline_offset-6__me2uf .Headline_headline__bottom__yA_No {
  left: -5.9rem;
}

.Headline_offset-7__NUWUQ .Headline_headline__bottom__yA_No {
  left: -6.9rem;
}

.Headline_offset-8__RNfr2 .Headline_headline__bottom__yA_No {
  left: -7.9rem;
}

.Headline_headline__bottom__yA_No:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  transform: skew(-7deg);
  transition: background 0.2s;
  background-color: #c73646;
}

html[data-theme="purple"] .Headline_headline__bottom__yA_No:before {
  background-color: #99258d;
}

html[data-theme="tiber"] .Headline_headline__bottom__yA_No:before {
  background-color: #0c3849;
}

html[data-theme="sand"] .Headline_headline__bottom__yA_No:before {
  background-color: #f6f6f6;
}

html[data-theme="green"] .Headline_headline__bottom__yA_No:before {
  background-color: #320564;
}

html[data-theme="ultra"] .Headline_headline__bottom__yA_No:before {
  background-color: #006a49;
}

html[data-theme="summer"] .Headline_headline__bottom__yA_No:before {
  background-color: #f47d30;
}

html[data-theme="midnight"] .Headline_headline__bottom__yA_No:before {
  background-color: #f6aa00;
}

html[data-theme="ibr"] .Headline_headline__bottom__yA_No:before {
  background-color: #1f5ab1;
}

html[data-theme="melavollur"] .Headline_headline__bottom__yA_No:before {
  background-color: #817f7a;
}

html[data-theme="skautaholl"] .Headline_headline__bottom__yA_No:before {
  background-color: #033a70;
}

.Headline_headline__bottomContent__TORWf {
  display: block;
  position: relative;
  z-index: 2;
  color: #fff;
  transition: color 0.2s;
}

html[data-theme="sand"] .Headline_headline__bottomContent__TORWf {
  color: #2e2e2e;
}

.Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #c73646;
}

html[data-theme="purple"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #99258d;
}

html[data-theme="tiber"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #0c3849;
}

html[data-theme="sand"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #2e2e2e;
}

html[data-theme="green"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #320564;
}

html[data-theme="ultra"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #006a49;
}

html[data-theme="summer"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #f47d30;
}

html[data-theme="midnight"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #176099;
}

html[data-theme="ibr"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #d91920;
}

html[data-theme="melavollur"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #2e2e2e;
}

html[data-theme="skautaholl"] .Headline_isLink__Q8SyN .Headline_headline__bottomContent__TORWf {
  color: #033a70;
}

.Headline_headline__bottomIcon__3SlWl {
  margin-right: 1.5rem;
}

.Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="green"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="ibr"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="melavollur"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="midnight"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="purple"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="sand"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="skautaholl"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="summer"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="tiber"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before,
html[data-theme="ultra"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:before {
  background-color: #fff;
}

.Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #c73646;
}

html[data-theme="purple"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #99258d;
}

html[data-theme="tiber"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #0c3849;
}

html[data-theme="sand"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #f6f6f6;
}

html[data-theme="green"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #320564;
}

html[data-theme="ultra"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #006a49;
}

html[data-theme="summer"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #f47d30;
}

html[data-theme="midnight"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #f6aa00;
}

html[data-theme="ibr"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #1f5ab1;
}

html[data-theme="melavollur"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #817f7a;
}

html[data-theme="skautaholl"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #033a70;
}

html[data-theme="ibr"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover:before {
  background-color: #d91920;
}

.Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover .Headline_headline__bottomContent__TORWf {
  color: #fff;
}

html[data-theme="sand"] .Headline_headline__bottom__yA_No.Headline_isLink__Q8SyN:hover .Headline_headline__bottomContent__TORWf {
  color: #7b7b7b;
}

/* ------------Hero Section -------------- */
.ticket-design {
  position: absolute;
  right: 2%;
  bottom: 17%;
}

.ticket-design img {
  width: 519.35px;
  height: 322.83px;
  object-fit: cover;
}

.start-btn {
  position: relative;
  right: 2.3%;
  height: 48px;
  padding: 12px 20px;
  background-color: #c73646;
  border-radius: 24px;
  /* gap: 10px; */
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: "TT Norms Medium";
  font-style: italic;
}

.SlicesLayout_slicesLayout__bQjLe.SlicesLayout_hasSpacing__JN9aK {
  padding-bottom: 10rem;
}

@supports not (padding: min(1rem, 2vw)) {
  .SlicesLayout_slicesLayout__bQjLe.SlicesLayout_hasSpacing__JN9aK {
    padding-bottom: 6rem;
  }

  @media (min-width: 45em) {
    .SlicesLayout_slicesLayout__bQjLe.SlicesLayout_hasSpacing__JN9aK {
      padding-bottom: 10rem;
    }
  }
}

@supports not (padding: clamp(1rem, 2vw, 3rem)) {
  .SlicesLayout_slicesLayout__bQjLe.SlicesLayout_hasSpacing__JN9aK {
    padding-bottom: min(max(6rem, min(10vh, 10vw)), 10rem);
  }
}

@supports (padding: clamp(1rem, 2vw, 3rem)) {
  .SlicesLayout_slicesLayout__bQjLe.SlicesLayout_hasSpacing__JN9aK {
    padding-bottom: clamp(6rem, min(10vh, 10vw), 10rem);
  }
}

.Hero_hero__K4Cz8 {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.Hero_hero__K4Cz8.Hero_isEpic__ANONf {
  height: calc(100vh - 20rem);
  min-height: 60rem;
  max-height: 100rem;
  background: #ccc;
}

.Hero_hero__K4Cz8.Hero_hasImage__rBmEi {
  margin: 12rem 0 3rem;
}

@media (min-width: 45em) {
  .Hero_hero__K4Cz8.Hero_hasImage__rBmEi {
    margin-top: 0;
  }
}

.Hero_hero__overlay__hbU7K {
  padding: 19rem 0 9rem;
}

.Hero_hasImage__rBmEi .Hero_hero__overlay__hbU7K {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 0;
}

.Hero_hero__stripe__uZSWZ {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  transform: skew(-7deg);
}

@media (min-width: 45em) {
  .Hero_hero__stripe__uZSWZ {
    width: 30rem;
  }
}

@media (min-width: 67.5em) {
  .Hero_hero__stripe__uZSWZ {
    width: 40rem;
  }
}

.Hero_hero__container__qm0SV {
  margin: 0 auto;
  padding-left: 7rem;
  padding-right: 7rem;
  max-width: calc(129rem + 7rem * 2);
}

@supports not (padding: clamp(1rem, 2vw, 3rem)) {
  .Hero_hero__container__qm0SV {
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: calc(129rem + 7rem * 2);
  }

  @media (min-width: 45em) {
    .Hero_hero__container__qm0SV {
      padding-left: 5rem;
      padding-right: 5rem;
      max-width: calc(129rem + 7rem * 2);
    }
  }

  @media (min-width: 67.5em) {
    .Hero_hero__container__qm0SV {
      padding-left: 7rem;
      padding-right: 7rem;
      max-width: calc(129rem + 7rem * 2);
    }
  }
}

@supports (padding: clamp(1rem, 2vw, 3rem)) {
  .Hero_hero__container__qm0SV {
    max-width: calc(129rem + clamp(3rem, 5.4263565891vw, 7rem) * 2);
    padding-left: clamp(3rem, 5.4263565891vw, 7rem);
    padding-right: clamp(3rem, 5.4263565891vw, 7rem);
  }
}

.Hero_hero__outer__Zm_E6 {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.Hero_hero__wrap___D_D9 {
  display: inline-block;
  width: 100%;
}

/* .Hero_hero__wrap___D_D9 {
  display: flex;
  width: 100%;
} */
.Hero_hasImage__rBmEi .Hero_hero__wrap___D_D9 {
  padding-left: 3rem;
}

@media (min-width: 45em) {
  .Hero_hasImage__rBmEi .Hero_hero__wrap___D_D9 {
    padding-left: 20rem;
  }
}

.Hero_hero__headline__1KmOa {
  flex: 1 1 75%;
  position: relative;
  z-index: 2;
  max-width: 90rem;
}

/* .Hero_hasImage__rBmEi .Hero_hero__headline__1KmOa {
  max-width: 60rem;
} */
.Hero_hero__headline__1KmOa.two {
  left: -10px;
  top: -28px;
}

.Hero_hero__content__Pofa8 {
  margin-left: auto;
}

.Hero_hero__background__BnG4B {
  display: flex;
  align-items: flex-start;
  position: relative;
  max-height: 90vh;
  overflow: hidden;
  background: 50% no-repeat #eee;
}

@media (min-width: 67.5em) {
  .Hero_hero__background__BnG4B {
    min-height: 400px;
  }
}

@media (min-width: 67.5em) and (min-height: 1000px) {
  .Hero_hero__background__BnG4B {
    max-height: 900px;
  }
}

.Hero_hero__aspect__jz2xZ {
  display: none;
  width: 100%;
  height: 100vh;
}

@media (min-width: 45em) {
  .Hero_hero__aspect__jz2xZ.Hero_desktop__mn3_x {
    display: block;
  }
}

@media (max-width: 44.9375em) {
  .Hero_hero__aspect__jz2xZ.Hero_mobile__S6hX_ {
    display: block;
  }
}

.Hero_hero__image__GIBSR {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: calc(100% - -284px);

}

.Hero_hero__image__GIBSR.Hero_anchorTop__NY8He {
  object-position: center top;
}

.Hero_hero__image__GIBSR.Hero_anchorBottom__LVwrB {
  object-position: center bottom;
}

.Hero_hero__navigation__igPIe {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3rem;
  z-index: 100;
  will-change: transform;
}

@media (min-width: 26.25em) {
  .Hero_hero__navigation__igPIe {
    bottom: 6rem;
  }
}

.Hero_isSingle__RAzFH .Hero_hero__navigation__igPIe {
  display: none;
}

.Hero_hero__list__WWqcZ {
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  list-style: none;
  display: flex;
}

.Hero_hero__item__bcT6P {
  position: relative;
  flex: 0 1 10rem;
  margin-right: 3rem;
  height: 0.4rem;
  width: 10rem;
  overflow: hidden;
  background: hsla(0, 0%, 100%, 0.25);
  transform: skew(-14deg);
}

.Hero_hero__itemInner__6Yjso {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  transform: translateX(-100%);
  will-change: transform;
}

.Hero_slide__1e_oY {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  will-change: transform, opacity;
}

.Hero_slide__container__fj58e {
  margin: 0 auto;
  padding-left: 7rem;
  padding-right: 7rem;
  max-width: calc(129rem + 7rem * 2);
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 100%;
}

@supports not (padding: clamp(1rem, 2vw, 3rem)) {
  .Hero_slide__container__fj58e {
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: calc(129rem + 7rem * 2);
  }

  @media (min-width: 45em) {
    .Hero_slide__container__fj58e {
      padding-left: 5rem;
      padding-right: 5rem;
      max-width: calc(129rem + 7rem * 2);
    }
  }

  @media (min-width: 67.5em) {
    .Hero_slide__container__fj58e {
      padding-left: 7rem;
      padding-right: 7rem;
      max-width: calc(129rem + 7rem * 2);
    }
  }
}

@supports (padding: clamp(1rem, 2vw, 3rem)) {
  .Hero_slide__container__fj58e {
    max-width: calc(129rem + clamp(3rem, 5.4263565891vw, 7rem) * 2);
    padding-left: clamp(3rem, 5.4263565891vw, 7rem);
    padding-right: clamp(3rem, 5.4263565891vw, 7rem);
  }
}

.Hero_slide__wrap__X6cIc {
  position: relative;
  z-index: 1;
  padding-bottom: 6rem;
  width: 100%;
}

@media (min-width: 26.25em) {
  .Hero_slide__wrap__X6cIc {
    padding-bottom: 12rem;
  }
}

@media (min-width: 67.5em) {
  .Hero_slide__wrap__X6cIc {
    display: flex;
    align-items: flex-end;
  }
}

.Hero_slide__headline__xd3u9 {
  will-change: transform, opacity;
}

.Hero_slide__content__D4S00 {
  display: none;
  margin-left: auto;
}

@media (min-width: 67.5em) {
  .Hero_slide__content__D4S00 {
    display: block;
  }
}

.Hero_slide__background__QV_Gq {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  left: -7%;
  -webkit-clip-path: polygon(7% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(7% 0, 100% 0, 100% 100%, 0 100%);
}

.Hero_slide__background__QV_Gq:after,
.Hero_slide__background__QV_Gq:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 30%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 20%, transparent);
}

.Hero_slide__background__QV_Gq:after {
  top: auto;
  bottom: 0;
  height: 40%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), transparent);
}

.Hero_slide__video__NWbxl {
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover;";
  object-fit: cover;
}

.Hero_slide__overlay__qyyOQ {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  will-change: opacity;
  pointer-events: none;
  background: #000;
}

.Hero_slide__clipper__Dm_s4 {
  display: none;
}

/* form control */
.step input[type="text"],
.step input[type="email"],
.step input[type="tel"],
.step input[type="file"],
.step input[type="text"]:focus,
.step input[type="email"]:focus,
.step input[type="tel"]:focus,
.step input[type="file"]:focus,
.step textarea,
.step textarea:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  border-bottom: 1px solid #c73646;
  text-align: center;
  outline: none;
  max-width: 278px;
  width: 278px;
  height: 38px;
  font-family: "TT Norms Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 17.69px;
  text-align: center;
  color: #c73646;
}

.step textarea {
  resize: none;
  overflow: hidden;
}

.step textarea::placeholder {
  padding-top: 8px;
}

input::placeholder,
textarea::placeholder {
  color: #c73646;
  font-size: 16px;
  font-weight: 500;
  opacity: 50%;
  font-family: "TT Norms Regular";
}

.toggle-advertisement {
  max-width: 211px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-wrapper .arrow {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 55%;
  transform: translateY(-50%);
  fill: #c73646;
}

.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
  min-height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-section .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
  flex-direction: column;
}

.display-section-btns>div {
  display: flex;
  flex-direction: column;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 70em !important;
  height: 40em !important;
  background-color: #c73646 !important;
  color: #fff !important;
  border-radius: 25px !important;
}

div:where(.swal2-icon) {
  position: relative !important;
  box-sizing: content-box !important;
  justify-content: center !important;
  width: 19em !important;
  height: 4em !important;
  margin: -0.5em auto 0.6em !important;
  border: 0.25em solid rgba(0, 0, 0, 0) !important;
  border-radius: 50% !important;
  /* border-color: #000; */
  font-family: inherit !important;
  line-height: 5em !important;
  cursor: default !important;
  user-select: none !important;
}

.ticket-section button:hover,
.form-section button:hover,
.ticket-hero-section button:hover,
.thank-you-section button:hover,
.upload-image-section button:hover,
.image-container button:hover {
  background-color: #000 !important;
  color: #fff !important;
  transition: all 0.3s ease-in-out !important;
}

button:focus {
  outline: none;
  box-shadow: none;
  background: #c73646;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 25px !important;
}

div:where(.swal2-icon) {
  margin: 1.5em auto 0.6em !important;
}

.swal-content .form-control {
  padding: 2.375rem 3.75rem !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0 !important;
  border-radius: 50px !important;
  background: initial !important;
  background-color: #fff !important;
  color: red !important;
  /* color: #fff; */
  font-size: 21px !important;
  padding: 11px 40px !important;
  border: 1px solid #000 !important;
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
  background: rgb(0 0 0 / 70%) !important;
}


.modal.exit-modal {
  font-family: "TT Norms Regular";
  border-radius: 25px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 215px;
  background-color: #c73646;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 18px;
  align-items: center;
  color: #fff;
  overflow: hidden;
}

.modal.exit-modal .exit-modal-content h2 {
  padding-top: 20px;
  font-family: "TT Norms Medium";
  text-transform: uppercase;
  font-size: 24px;
  line-height: 26.54px;
  font-weight: 800;
  margin-bottom: 10px;
}

.exit-modal .exit-modal-content p {
  font-family: "TT Norms Regular";
  font-size: 24px;
  font-weight: 500;
  line-height: 26.54px;
  text-align: center;
  font-style: normal;
  margin-bottom: 33px;
}

.exit-modal button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 10px 10px;
  width: 150px;
  padding: 8px 16px 10px 16px;
  height: 32px;
  font-family: "TT Norms Regular";
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-align: center;
  color: #c73646;
  background: #fff;
  border-radius: 24px;
}

.cross-icon {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 3%;
  top: 14%;
  font-weight: normal;
  cursor: pointer;
}

.cross-icon:hover::before,
.cross-icon:hover::after {
  opacity: 0.7;
}

.cross-icon::before,
.cross-icon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: rgb(255, 255, 255);
}

.cross-icon::before {
  transform: rotate(45deg);
}

.cross-icon::after {
  transform: rotate(-45deg);
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 1799px) {
  .Hero_hero__headline__1KmOa.two {
    left: -8px;
  }

  .ticket-design img {
    width: 516.35px;
    height: 321.83px;
  }

  .Headline_headline__wrapper__bpYIi {
    font-size: 50px;
    line-height: 65px;
    font-weight: 900;
    font-family: "TT Norms Extra Bold";
  }

  .Hero_hasImage__rBmEi .Hero_hero__wrap___D_D9 {
    padding-left: 15rem;
  }

  .Hero_hero__stripe__uZSWZ {
    width: 31rem;
  }
}

@media screen and (max-width: 1599px) {
  .ticket-design {
    bottom: 21%;
  }
}

@media screen and (max-width: 1399px) {
  .Headline_headline__wrapper__bpYIi {
    font-size: 49px;
  }

  .ticket-design img {
    width: 477px;
    height: 297.83px;
  }

  .ticket-design {
    bottom: 19%;
  }
}

@media screen and (max-width: 1320px) {
  .Hero_hero__wrap___D_D9 {
    margin-top: 47px;
  }

  .Headline_headline__wrapper__bpYIi {
    font-size: 47px;
  }

  .ticket-design img {
    width: 466px;
    height: 287.83px;
  }
}

@media screen and (max-width: 1280px) {
  .Headline_headline__wrapper__bpYIi {
    font-size: 43px;
  }

  .ticket-design img {
    width: 450px;
    height: 280.83px;
  }
}

@media screen and (max-width: 1199px) {
  .social-platform-modal .modal-content {
    min-width: unset !important;
  }

  .ticket-design img {
    width: 428px;
    height: 269.83px;
  }

  .Headline_headline__wrapper__bpYIi {
    font-size: 40px;
  }
}

@media screen and (max-width: 1152px) {
  .ticket-design img {
    width: 420px;
    height: 260.83px;
  }

  .Headline_headline__wrapper__bpYIi {
    font-size: 36px;
  }

  .Hero_hero__wrap___D_D9 {
    margin-top: 69px;
  }
}

@media screen and (max-width: 1080px) {
  .Hero_hero__stripe__uZSWZ {
    left: -23px;
  }

  .Hero_hero__stripe__uZSWZ {
    width: 27rem;
  }

  .Hero_hasImage__rBmEi .Hero_hero__wrap___D_D9 {
    padding-left: 9rem;
  }

  .ticket-design img {
    width: 420px;
    height: 260.83px;
  }

  .Headline_headline__wrapper__bpYIi {
    font-size: 36px;
  }

  .Hero_hero__wrap___D_D9 {
    margin-top: 69px;
  }
}

@media screen and (max-width: 1020px) {
  .ticket-design {
    bottom: 23%;
  }

  .Hero_hero__stripe__uZSWZ {
    left: -23px;
  }

  .Hero_hero__stripe__uZSWZ {
    width: 27rem;
  }

  .Hero_hasImage__rBmEi .Hero_hero__wrap___D_D9 {
    padding-left: 9rem;
  }

  .ticket-design img {
    width: 391px;
    height: 239.83px;
  }

  .Headline_headline__wrapper__bpYIi {
    font-size: 34px;
  }

  .Hero_hero__wrap___D_D9 {
    margin-top: 69px;
  }
}

@media screen and (max-width: 991px) {
  .thanks-heading {
    top: 59%;
  }

  .thanks-heading h3 {
    margin-bottom: 30px;
  }

  .Hero_hero__stripe__uZSWZ {
    width: 32rem;
  }

  .thank-you-section h3 {
    font-size: 40px;
  }

  .section-heading {
    font-size: 42px;
    line-height: 45px;
  }

  .ticket-section img {
    width: 528px;
    height: 319px;
  }

  .Hero_hero__wrap___D_D9 {
    margin-top: 0;
  }

  .ticket-design {
    bottom: 12%;
  }
}

@media screen and (max-width: 767px) {
  .Headline_headline__wrapper__bpYIi.two:before{
    top: -1rem;
  }
  .modal.exit-modal {
    width: 538px;
  }

  .lg-window-size,
  .max-window-size {
    margin-top: 7px;
  }

  .Hero_hero__stripe__uZSWZ {
    display: none;
  }

  /* .Hero_hasImage__rBmEi .Hero_hero__wrap___D_D9{display: none;} */
  .start-btn {
    position: absolute;
    bottom: 3%;
    right: unset;
    right: 6%;
  }

  .ticket-section img {
    width: 303.34px;
    height: 188.56px;
  }

  .ticket-section h2 {
    margin-bottom: 54px;
  }

  .section-heading {
    font-size: 24px;
    line-height: 28px;
    max-width: 204px;
    margin: 0px auto;
    flex-wrap: wrap;
    white-space: normal;
  }

  .ticket-design img {
    width: 369px;
    height: 220.83px;
  }

  .ticket-section .ticket-name {
    max-width: 250px;
  }

  .ticket-name {
    font-size: 21px;
    line-height: 27px;
  }

  /* .Header_header__skew__UqQYk{display: none;} */
}

@media screen and (max-width: 720px) {
  .Header_header__NnOSM.Header_isRegular__qmthJ {
    padding: 12px 0px 7px 0px;
    background: #fff;
    top: 0rem;
  }

  .Hero_hero__image__GIBSR {
    position: fixed;
  }

  .thanks-heading {
    top: 2%;
    left: 7%;
  }

  .Headline_headline__wrapper__bpYIi {
    font-family: "TT Norms Extra Bold" !important;

  }

  .thanks-heading h3 {
    font-family: "TT Norms Extra Bold" !important;
    font-size: 24px;
    line-height: 28px;
    max-width: 174px;
    white-space: normal;
    text-align: left;
    margin-bottom: 45px;
  }

  .Headline_headline__wrapper__bpYIi {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
  }

  .Headline_headline__wrapper__bpYIi {
    font-size: 16px;
    line-height: 0px;
    letter-spacing: -1px;
    margin-bottom: 0px;
    padding: 3px 6px 0px 3px;
  }

  .Hero_hero__headline__1KmOa.two {
    left: -3px;
  }

  .Hero_hero__headline__1KmOa.two {
    top: 0px;
  }

  .Hero_hero__stripe__uZSWZ {
    display: none;
  }

  .Hero_hasImage__rBmEi .Hero_hero__wrap___D_D9 {
    display: none;
  }

  .start-btn {
    position: absolute;
    bottom: 3%;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }

  .ticket-design img {
    width: 290px;
    height: 183px;
    object-fit: cover;
  }

  .ticket-design {
    bottom: 36%;
    right: 10%;
    transform: rotate(-9.05deg);
  }

  .start-btn {
    bottom: 6%;
  }

  .Header_header__skew__UqQYk {
    display: none;
  }
}

@media (min-height: 635px) and (max-height: 645px) {

  /* CSS rules here will apply when the viewport height is in the range of 635 to 645 pixels */
  .steps {
    padding-top: 15px !important;
  }

  .slider-container .slider-btn {
    font-size: 15px;
  }
}

@media screen and (max-width: 575px) {

  .Hero_hero__image__GIBSR {
    object-position: unset;
  }

  .display-section {
    padding-top: 60px;
  }

  .steps {
    padding-top: 33px !important;
  }

  .storyImage-overlay.ticket-overlay .ticket-name {
    overflow: hidden;
    /* display: -webkit-box; */
    -webkit-line-clamp: 2;
    max-height: calc(1em * 2) !important;
    /* max-width: 100%; */
    /* margin: 0px auto; */
    box-shadow: none !important;
    /* font-weight: 900 !important; */
    /* font-family: "TT Norms Extra Bold" !important; */
    text-align: center !important;
    color: #fff !important;
    display: inline-block !important;
  }

  .social-platform-modal .modal-content {
    min-width: unset !important;
    max-width: 367px;
    margin: 0px auto;
  }

  .social-platform-modal .modal-header {
    position: unset !important;
  }

  .social-platform-modal .cross-icon {
    position: absolute;
    right: 8%;
    top: 13%;
    width: 16px;
  }

  .social-platform-modal .modal-content {
    padding: 20px 0px !important;
  }

  .social-platform-modal .modal-content p {
    font-size: 20px;
    font-weight: 800;
    line-height: 22.11px;
    max-width: 214px;
    margin: 25px auto;
  }

  .social-platform-modal .modal-header {
    padding: 0;
  }

  .modal.exit-modal .exit-modal-content h2 {
    max-width: 210px;
    margin: 0px auto;
    font-size: 20px;
    font-weight: 800;
    line-height: 22.11px;
    text-align: center;
    margin-bottom: 15px;

  }

  .exit-modal .exit-modal-content p {
    max-width: 276px;
    font-size: 20px;
    font-weight: 500;
    line-height: 22.11px;
    text-align: center;
    margin: 0px auto;
    margin-bottom: 40px;

  }

  .exit-modal button {
    width: 110px;
    font-weight: 800;
    font-family: 'TT Norms Medium';
  }

  .modal.exit-modal {
    width: 348px;
    height: 255px;
  }

  .modal.exit-modal .cross-icon {
    width: 16px;
    height: 16px;
    right: 6%;
    top: 9%;
  }

  .ticket-section .ticket-name {
    padding: 0 16px;
  }

  .Hero_hero__K4Cz8 {
    overflow: hidden !important;
  }

  /* .Hero_hero__image__GIBSR{
    height: 100vh !important;
    overflow: hidden !important;
  } */
  .slider-container .slider-btn {
    margin: 0px 6px;
    font-size: 14px;
  }

  .Header_header__NnOSM.Header_isRegular__qmthJ {
    padding: 12px 20px 7px 20px;
    background: #fff;
    top: 0rem;
  }

  .step-content {
    width: 100%;
    padding: 20px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .arrow.arrow--right {
    right: 0%;
  }

  .arrow.arrow--left {
    left: 0%;
  }

  .slider-btn:hover {
    background: none !important;
    color: #c73646 !important;
  }

  .upload-image-section .story-area,
  .upload-image-section .post-area {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .swal2-icon-content img {
    width: 150px !important;
  }

  .Header_header__logo__iOMtl {
    width: 160px;
    height: 42px !important;
    margin-left: unset;
  }

  .Header_header__NnOSM {
    top: 2rem;
    padding: 0px 20px;
  }

  .Hero_hero__wrap___D_D9 {
    display: none;
  }

  .ticket-design {
    position: absolute;
    right: 10%;
    bottom: 42%;
    transform: rotate(-9.05deg);
  }

  .start-btn {
    position: absolute;
    z-index: 9999999;
    bottom: 17%;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'TT Norms Regular' !important;
  }

  .start-btn.two {
    margin-bottom: 20px;
    position: absolute;
    z-index: 9999999;
    bottom: 24%;
    padding-left: 27px;
    padding-right: 27px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'TT Norms Regular' !important;
  }
  .start-btn.three {
    margin-bottom: 20px;
    position: absolute;
    z-index: 9999999;
    bottom: 32%;
    padding-left: 27px;
    padding-right: 27px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'TT Norms Regular' !important;
  }

  .ticket-design img {
    width: 207px;
    height: 128px;
    object-fit: cover;
  }

  .section-heading {
    font-size: 24px;
    max-width: 300px;
    font-weight: 900;
    line-height: 28px;
    white-space: nowrap;
    margin: 0px auto;
  }

  .ticket-section {
    margin-bottom: 80px;
  }

  .form-section .section-heading,
  .ticket-section .section-heading {
    max-width: 250px;
    white-space: normal;
  }

  .form-section .section-heading {
    margin-bottom: 53px;
  }

  .form-section {
    margin-bottom: 22px;
  }

  .ticket-section img {
    width: 303px;
    height: 188px;
    object-fit: cover;
  }

  .ticket-name {
    font-size: 32px;
    line-height: 35px;
    top: 50%;
  }

  .story-area img {
    border-radius: 20px;
  }

  .desktop-mode {
    display: none;
  }

  .responsive-mode {
    display: block;
  }

  .responsive-controls {
    margin-top: 10px;
  }

  .swal2-title {
    font-size: 20px !important;
  }

  .swal-content .form-control {
    padding: 1.375rem 2.75rem !important;
  }

  div:where(.swal2-container) div:where(.swal2-actions) {
    margin: 0 !important;
  }

  .steps {
    padding-top: 50px;
  }

  .image-section-1 .storyImage-overlay.ticket-overlay {
    bottom: 7%;
    transform: rotate(7.82deg);
    width: 179px;
    height: 111px;
  }

  .image-section-1 .postImage-overlay.ticket-overlay {
    bottom: 7%;
    transform: rotate(-7.82deg);
    width: 128px;
    height: 79px;
  }

  .display-section-btns.responsive-capture-btns {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .arrow--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  /* Adjust the translation of the slides */
  .keen-slider__slide.number-slide2 .keen-slider__slide {
    transform: translateX(0) !important;
    /* Adjust the translation as needed */
  }

  .story-area,
  .upload-image-section .image-section-1,
  .story-area .cropper-modal {
    width: 278px;
    overflow: visible !important;
  }

  .post-area,
  .upload-image-section .image-section-2 {
    width: 278px;
    overflow: hidden;
  }

  .upload-image-section .edit-ticket-icon {
    right: -7px;
    top: -7px;
  }

  .story-area,
  .image-section-1,
  .story-area .cropper-modal,
  .story-area img {
    height: 430.22px;
  }

  .upload-image-section {
    margin-bottom: 0px;
  }

  /* .Header_header__logo__iOMtl {
    width: 160px;
  } */
  .Hero_hero__K4Cz8.Hero_hasImage__rBmEi {
    margin: 7rem 0 0rem 0rem;
  }

  .steps.steps-1 {
    padding-top: 10px !important;
  }
}

@media screen and (max-width: 425px) {
  .ticket-section img {
    width: 303px;
    height: 177px;
    object-fit: cover;
  }
  .start-btn.three {margin-bottom: 28px;}
}

@media screen and (max-width: 420px) {
  .ticket-section img {
    width: 264.34px;
    height: 164.56px;
    object-fit: cover;
  }

  .ticket-section img {
    margin-bottom: 80px;
  }

  .ticket-section img {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 375px) {
  .ticket-name {
    font-size: 29px;
    line-height: 35px;
    top: 43%;
  }
  .start-btn.three {margin-bottom: 35px;}
}

@media screen and (max-width: 320px) {
  .ticket-section .ticket-name {
    max-width: 170px;
  }

  .story-area,
  .upload-image-section .image-section-1,
  .story-area .cropper-modal,
  .story-area img,
  .story-area,
  .image-section-1,
  .story-area .cropper-modal {
    width: 250px;
    overflow: hidden;
  }

  .post-area,
  .upload-image-section .image-section-2,
  .post-area img,
  .post-area,
  .image-section-2 {
    width: 250px;
    overflow: hidden;
  }

  .ticket-section .ticket-name {
    font-size: 29px;
    line-height: 33px;
    top: 42%;
    font-size: 24px;
  }

  .ticket-section img {
    width: 256.34px;
    height: 158.56px;
    object-fit: cover;
  }

  .ticket-section img {
    margin-bottom: 63px;
  }

  .modal.exit-modal {
    width: 295px;
  }
}

@media (max-height:900px) {
  .ticket-section {
    margin-bottom: 98px;
  }

  .upload-image-section {
    margin-bottom: 8px;
  }

  .display-section {
    padding-top: 73px !important;
  }
}

.step-content {
  padding: 0px 20px;
}

/* Media query for 1600x900 landscape */
@media (max-width: 1600px) and (max-height: 900px) and (orientation: landscape) {
  .max-window-size {
    margin-top: 4px;
  }

  .thank-you-section h3 {
    margin-bottom: 208px;
  }

  .Hero_hero__stripe__uZSWZ {
    width: 36rem;
    height: 100vh;
  }

  .thanks-heading {
    left: 13%;
  }

  .start-btn {
    top: -13px;
  }

  .ticket-design {
    bottom: 10%;
  }

  .ticket-section {
    margin-bottom: 60px;
  }

  .ticket-section .section-heading {
    font-size: 42px;
    margin-bottom: 55px;
  }

  .ticket-section img {
    width: 500px;
    height: 310px;
    margin-bottom: 40px;
  }

  .ticket-section .ticket-name {
    top: 55.8%;
  }

  .form-section .section-heading {
    margin-bottom: 74px;
  }

  .form-section {
    margin-bottom: 90px;
  }

  .editor-content,
  .image-container {
    justify-content: space-evenly !important;
  }

  .story-area,
  .image-section-1,
  .story-area .cropper-modal,
  .story-area img {
    height: 400px;
    width: 225px;
    /* overflow: hidden; */
  }

  .post-area,
  .image-section-2,
  .post-area .cropper-modal,
  .post-area img {
    height: 220px;
    width: 220px;
    overflow: hidden;
  }

  .post-area .cropper-modal,
  .post-area .cropper-wrap-box,
  .post-area .cropper-bg {
    height: 220px !important;
  }

  .editor-content,
  .image-container {
    justify-content: space-around;
  }

  .upload-image-section {
    margin-bottom: 15px;
  }

  section {
    padding-top: 88px;
  }

  /* .image-section-1 .storyImage-overlay.ticket-overlay {
  width: 150px;
}
.image-section-2 .postImage-overlay.ticket-overlay{
  width: 100px;
} */
}

@media (max-width: 1280px) and (max-height: 1024px) and (orientation: landscape) {
  .thanks-heading {
    left: 14%;
  }

  .Hero_hero__stripe__uZSWZ {
    width: 35rem;
  }
}

/* Media query for 1366x768 landscape */
@media (max-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
  .ticket-design {
    bottom: 12%;
  }

  .start-btn {
    top: -10px;
  }
}

/* Media query for 1280x800 landscape */
@media (max-width: 1280px) and (max-height: 800px) and (orientation: landscape) {
  .ticket-design {
    bottom: 12%;
  }

  .start-btn {
    top: -10px;
  }

  .ticket-section {
    margin-bottom: 60px;
  }

  .ticket-section .section-heading {
    font-size: 42px;
    margin-bottom: 55px;
  }

  .ticket-section img {
    width: 500px;
    height: 310px;
    margin-bottom: 40px;
  }
}

@media (max-width: 1280px) and (max-height: 720px) and (orientation: landscape) {
  .ticket-section {
    margin-bottom: 30px;
  }

  .ticket-section .section-heading {
    font-size: 42px;
    margin-bottom: 55px;
  }

  .ticket-section img {
    width: 450px;
    height: 285px;
    margin-bottom: 40px;
  }

  .form-section {
    margin-bottom: 64px;
  }

  .upload-image-section {
    margin-bottom: -15px;
  }
}

@media (max-width: 2560px) and (max-height: 1440px) {
  .Hero_hero__background__BnG4B {
    max-height: 90vh;
  }
}
@media (max-width: 991px) {
  .thank-you-section-btns {
    justify-content: center !important;
    flex-wrap: wrap;
    position: relative;
    top: -92px;
}
  .thank-you-section-btns{justify-content: center !important; flex-wrap: wrap;position: relative;top: -92px;}
  .thank-you-section-btns button{margin-bottom: 10px;}
}

@media (max-width: 767px) and (max-height: 767px) {

  .story-area,
  .image-section-1,
  .story-area .cropper-modal,
  .story-area img {
    height: 360px;
    width: 202.50px;
  }

  .post-area,
  .image-section-2,
  .post-area .cropper-modal,
  .post-area img {
    height: 278px;
    width: 278px;
  }

  .steps {
    padding-top: 15px;
  }

  /* .upload-image-section{
    padding-top: 30px;
  } */
  .display-section {
    padding-top: 45px;
    margin-bottom: 0px;
  }

  section {
    padding-top: 75px;
  }

  .upload-image-section .upload-area .upload-image-description,
  .display-section .upload-image-description {
    font-size: 14px;
  }

  .navigation-wrapper .arrow {
    top: 58%;
  }
}

@media (max-height: 650px) {

  .story-area,
  .image-section-1,
  .story-area .cropper-modal,
  .story-area img {
    height: 280px;
  }

  .post-area,
  .image-section-2,
  .post-area .cropper-modal,
  .post-area img {
    height: 225px;
  }

  .image-section-1 .storyImage-overlay.ticket-overlay {
    width: 179px;
  }

  .image-section-1 .storyImage-overlay.ticket-overlay .ticket-name {
    font-size: 19.24px;
    line-height: 20.78px;
  }

  .image-section-2 .postImage-overlay.ticket-overlay {
    width: 128px;
  }

  .image-section-2 .postImage-overlay.ticket-overlay .ticket-name {
    font-size: 13px;
    line-height: 14.84px;
  }

  .display-section .steps {
    padding-top: 0;
  }

  .display-section {
    padding-top: 58px;
  }
}

.custom-heading {
  display: inline-block;
  z-index: 9999;
  position: relative;
}

.custom-heading h1 {
  font-size: 50px;
  display: flex;
  flex-direction: column;
}

.custom-heading h1::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  bottom: -1.6rem;
  z-index: -1;
  transform: skew(-7deg);
  background-color: #c73646;
  width: 100%;
}

.abc {
  display: flex;
  flex-direction: column;
}

.ticket-error {
  font-family: 'TT Norms Regular';
  font-size: 16px;
  font-weight: 500;
  line-height: 17.69px;
  text-align: center;
}

.story-area.overflowy-scroll,
.overflowy-scroll {
  overflow-y: hidden;
  overflow-x: hidden;
}

.story-area {
  overflow-y: hidden;
  overflow-x: hidden;
}

.swal2-close {
  font-size: 50px !important;
  color: #fff;
  box-shadow: none !important;
  outline: none !important;
}

button.swal2-close:hover,
button.swal2-close:focus {
  opacity: 0.7;
  background-color: none !important;
  color: #fff !important
}

div:where(.swal2-container) div:where(.swal2-popup) {
  height: 20em !important;
}

.storyImage-overlay.ticket-overlay .ticket-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  max-height: calc(1em * 2);
  box-shadow: none !important;
  text-align: center !important;
  color: #fff !important;
}

.postImage-overlay.ticket-overlay .ticket-name {
  overflow: hidden;
  -webkit-line-clamp: 2;
  max-height: calc(1em * 2);
  box-shadow: none !important;
  text-align: center !important;
  color: #fff !important;
}

/* @media only screen 
  and (min-device-width: 1440px) 
  and (max-device-width: 2880px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (min-resolution: 192dpi) {
  .ticket-section{
    margin-bottom: 16px;
  }
} */

/* .story-area img ,.post-area img{
  height: 100%;width: 100%;object-fit: fill;}
    */
.storyImage,
.postImage {
  visibility: visible;
  /* Make sure it's visible */
  display: block;
  /* Prevents it from being display:none */
  opacity: 1;
  /* Ensures it's fully opaque */
}

/* loader */
.background-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100vh;
  display: none;
  z-index: 999999;
}

.loader {
  position: fixed;
  z-index: 301;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
  width: 200px;
  overflow: hidden;
  text-align: center;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 303;
  border-radius: 100%;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

.spinner1 {
  width: 100px;
  height: 100px;
  border: 10px solid #c73646;
  animation: spin 1s linear infinite;
}

.spinner2 {
  width: 70px;
  height: 70px;
  border: 10px solid #c73646;
  animation: negative-spin 2s linear infinite;
}

.spinner3 {
  width: 40px;
  height: 40px;
  border: 10px solid #c73646;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes negative-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.z-indexx {
  z-index: 99999;
}

* {
  font-variant-ligatures: none !important
}

/* h1, h2, h3, h4, h5, h6,span,button,a {
    font-variant-ligatures: none !important;
  } */

/* Modal styles */
.iphone-modal {
  display: none;
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
}

.iphone-modal .modal-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 15% auto;
  width: 100%;
  max-width: 100%;
  background: transparent;
  border-radius: 10px;
  text-align: center;
  border: none;

}

#displayedImage {
  position: relative;
  width: 100%;
  height: 450px;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
}


/* Style the close icon */
.iphone-modal .close {
  position: absolute;
  top: 20%;
  right: 6%;
  font-size: 30px;
  cursor: pointer;
  z-index: 999999;
  color: #c73646;
  border: 1px solid #c73646;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iphone-modal .close:hover {
  opacity: 0.7;
}

@media (min-width: 1024px) and (max-height: 684px) {
  .upload-image-section {
    padding-top: 40px !important;
  }
  .display-section{
    padding-top: 25px !important;
  }
  .ticket-section{
    padding-top: 75px !important;
  }
}

.language-changer{z-index: 99999;margin-right: 30px;}
.language-changer select{font-family: "TT Norms Regular";
  line-height: 17.69px;
  text-align: center;
  padding: 3px 12px;
  color: #ffffff;
  background: #c73646;
  white-space: nowrap !important;
  border: none;
}
.language-changer .language-select__menu{z-index: 999;border: none !important;cursor:  pointer !important;}
.lang-select__option{color: #c73646!important;cursor:  pointer !important; background-color: transparent !important;margin-bottom: 2px !important;font-weight: 600;}
.lang-select__indicator{color: #fff !important;}
.Header_header__NnOSM{overflow: visible;}
.lang-select__single-value{color: #fff !important;font-weight: 600;}
 .lang-select__control{background-color: #c73646!important; color: #fff !important;border: none !important;cursor: pointer !important;}
 .lang-select__control--is-focused{border: none !important;box-shadow: none !important;color: #fff !important;}
 .lang-select__option--is-selected{color: #fff !important; background-color: transparent !important;}
 .lang-select__input-container{color: #fff !important;}
.lang-select__option--is-selected{background-color: #c73646 !important;}
.lang-select__option:hover{background-color: #c73646 !important; color: #fff !important;}
.iphone-modal .iphone-guide{max-width: 235px;width: 235px; font-family: "TT Norms Heavy";font-style: italic; font-size: 20px;font-weight: 900;line-height: 22.69px; position: absolute;left: 50%; top: 11%;transform: translateX(-50%); z-index: 9999;color: #C73646;display: inline-block;text-align: center;align-content: center;}
.thank-you-information{max-width: 500px;transform: skew(-8deg);background: #fff; margin-left: -16px;}
.thank-you-information h5{color: #cb334a;padding: 15px;font-size: 30px;display: inline-block;font-family: "TT Norms Extra Bold";}
.step-content.thank-you {max-width: 100%;width: 100%;}
.steps .dot:nth-child(6) {display: none;}
.step-content.thank-you{padding: 0px;}
@media (max-width: 767px){
  .thank-you-section-btns{top: unset;justify-content: flex-end !important;position: unset;}
  .language-changer{margin-right: unset;}
  .thank-you-information {
    max-width: 252px;
    transform: skew(-8deg);
    width: 201px;
    margin-left: -6px !important;
}
.thank-you-information h5 {
  color: #cb334a;
  padding: 8px;
  font-size: 12px;
}
}
@media only screen and (max-width: 479px)  {
     #displayedImage{height: 410px !important;}
}
